import React, { useState, useEffect } from 'react';
import { Switch, Input, Button, LinearProgress, Alert } from '@mui/material';
import { useNavigate } from 'react-router';
import axios from "axios";
import {
    tokenUsuario,
    urlListarRecursosAvancado,
    urlAlterarHorariosAtendimento,
    urlFinalizarAtendimentoDia,
    urlSincronizarContatos,
    urlStatusSessaoW,
    urlIniciarSessaoW,
    urlDesconectarSessaoW,
} from '../../utils/constants';
import { inputForm, buttonWhiteForm } from '../../styles/style';
import { MenuSide } from '../../components/MenuSide';
import { AlertCustom } from "../../components/AlertCustom";

//Accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//Accordion
import ButtonCustom from '../../components/ButtonCustom';

export default function Avancado() {

    const navigate = useNavigate();

    const obterNomeDoDiaDaSemana = (dia) => {
        switch (dia) {
            case 1:
                return 'Segunda-feira';
            case 2:
                return 'Terça-feira';
            case 3:
                return 'Quarta-feira';
            case 4:
                return 'Quinta-feira';
            case 5:
                return 'Sexta-feira';
            case 6:
                return 'Sábado';
            case 7:
                return 'Domingo';
            default:
                return 'Dia inválido';
        }
    };

    const ItemDiaDaSemana = ({ dia, status, horario_inicial, horario_final, retornoInformacoesDia }) => {
        let nomeDia = obterNomeDoDiaDaSemana(dia);
        const [statusDia, setStatusDia] = useState(status);
        const [horarioInicialDia, setHorarioInicialDia] = useState(horario_inicial);
        const [horarioFinalDia, setHorarioFinalDia] = useState(horario_final);

        const handleStatusChange = () => {
            const novoStatus = statusDia === 1 ? 0 : 1;
            setStatusDia(novoStatus);
            retornoInformacoesDia(novoStatus, horarioInicialDia, horarioFinalDia);
        };

        const handleHorarioInicialChange = (event) => {
            const novoHorarioInicial = event.target.value;
            setHorarioInicialDia(novoHorarioInicial);
            retornoInformacoesDia(statusDia, novoHorarioInicial, horarioFinalDia);
        };

        const handleHorarioFinalChange = (event) => {
            const novoHorarioFinal = event.target.value;
            setHorarioFinalDia(novoHorarioFinal);
            retornoInformacoesDia(statusDia, horarioInicialDia, novoHorarioFinal);
        };

        return (
            <>

                <div style={{ borderRadius: '0.5rem', padding: 10 }}>
                    <br />
                    <h6 style={{ marginBottom: 20 }}>{nomeDia}</h6>

                    <div className='row'>

                        <div className='col-sm-4'>
                            <h6>Horário inicial</h6>
                            <Input
                                value={horarioInicialDia}
                                type="time"
                                style={inputForm}
                                onChange={handleHorarioInicialChange}
                            />
                        </div>
                        <div className='col-sm-4'>
                            <h6>Horário final</h6>
                            <Input
                                value={horarioFinalDia}
                                type="time"
                                style={inputForm}
                                onChange={handleHorarioFinalChange}
                            />
                        </div>
                        <div className='col-sm-4'>
                            <h6>Status</h6>
                            <Switch
                                checked={statusDia === 1}
                                onChange={handleStatusChange}
                            />
                        </div>
                    </div>
                    <br />
                </div>
                <br />
            </>
        )
    }

    const carregarRecursos = async () => {
        try {
            const response = await axios.post(urlListarRecursosAvancado, {
                token: tokenUsuario
            })
            return response.data;
        } catch (error) {
            console.log(error)
        }
    }

    const [dias, setDias] = useState([]);
    const [diaFinalizar, setDiaFinalizar] = useState([]);
    const [successAlert, setSuccessAlert] = useState(false);
    const [erroAlert, setErrorAlert] = useState(false);

    const [statusConexaoW, setStatusConexaoW] = useState(false);
    const [qrCodeConexaoW, setQrCodeConexaoW] = useState(null);

    const [alertSuccessSincContato, setAlertSuccessSincContato] = useState(false);
    const [alertErroSincContato, setAlertErroSincContato] = useState(false);
    const [textoSincContato, setTextoSincContato] = useState('Sincronizar contatos');
    const [sincronizandoCtt, setSincronizandoCtt] = useState(false);

    const fecharSucessAlert = () => {
        setSuccessAlert(false);
    };

    const fechaErrorAlert = () => {
        setErrorAlert(false);
    };

    const listarRecursos = async () => {
        const horariosAtendimento = await carregarRecursos();
        setDias(horariosAtendimento.horarios_atendimento);
    }

    useEffect(() => {
        listarRecursos();
    }, [])


    const atualizarDia = (index, novoStatus, novoHorarioInicial, novoHorarioFinal) => {
        const novosDias = [...dias];
        novosDias[index] = {
            ...novosDias[index],
            status: novoStatus,
            horario_inicial_1: novoHorarioInicial,
            horario_final_1: novoHorarioFinal,
        };
        setDias(novosDias);
    };

    const alterarHorarios = async () => {
        try {
            const response = await axios.post(urlAlterarHorariosAtendimento, {
                token: tokenUsuario,
                dias: dias,
            })
            if (response.data === 1) {
                setSuccessAlert(true);
            } else {
                setErrorAlert(true);
            }
        } catch (error) {
            setErrorAlert(true);
            console.log(error)
        }
    }

    const finalizarAtendimentoPorDia = async () => {
        try {
            const response = await axios.post(urlFinalizarAtendimentoDia, {
                token: tokenUsuario,
                dia: diaFinalizar,
            })
            if (response.data === 1) {
                setSuccessAlert(true);
            } else {
                setErrorAlert(true);
            }
        } catch (error) {
            setErrorAlert(true);
            console.log(error)
        }
    }

    const sincronizarContatos = async () => {
        setSincronizandoCtt(true);
        setTextoSincContato('Sincronizando');
        try {
            const response = await axios.post(urlSincronizarContatos, {
                token: tokenUsuario,
            }
            );
            if (response.data.status == 'sincronizado') {
                setSincronizandoCtt(false);
                setAlertSuccessSincContato(true);
                setTextoSincContato('Sincronizar contatos');
                setTimeout(() => {
                    setAlertSuccessSincContato(false);
                }, 3000);
            } else {
                setTextoSincContato('Sincronizar contatos');
                setSincronizandoCtt(false);
                setAlertErroSincContato(true);
            }
        } catch (error) {
            setTextoSincContato('Sincronizar contatos');
            setSincronizandoCtt(false);
            setAlertErroSincContato(true);
        }
    };

    const InicarSessaoW = async () => {
        try {
            const response = await axios.post(urlIniciarSessaoW, {
                token: tokenUsuario,
            })
            console.log(response);
        } catch (error) {
            console.log(error)
        }
    }

    const StatusSessaoW = async () => {
        try {
            const response = await axios.post(urlStatusSessaoW, {
                token: tokenUsuario,
            })
            if (response) {
                setQrCodeConexaoW(response.data.qrcode);
            }
            if (response.data.status === 'CONNECTED') {
                setStatusConexaoW(true);
            } else {
                setStatusConexaoW(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const DesconectarSessaoW = async () => {
        try {
            const response = await axios.post(urlDesconectarSessaoW, {
                token: tokenUsuario,
            })
            if (response) {
                setStatusConexaoW(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        StatusSessaoW();
        setInterval(() => {
            StatusSessaoW();
        }, 10000);
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br /> <div className='card'
                        style={{ marginRight: '1%', }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Recursos avançados
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>

                            <AlertCustom
                                texto={"Informações atualizadas com sucesso!"}
                                color="success"
                                isOpen={successAlert}
                                setIsOpen={fecharSucessAlert}
                                tempo={2000}
                            />
                            <AlertCustom
                                texto={"Não foi possível atualizar as informações!"}
                                color="error"
                                isOpen={erroAlert}
                                setIsOpen={fechaErrorAlert}
                                tempo={3000}
                            />
                            <div>
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Configurações de horário de atendimento</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <div className='row'>
                                                {dias.map((item, index) => (
                                                    <React.Fragment key={item.id}>
                                                        <div className='col-sm-4'>
                                                            <ItemDiaDaSemana
                                                                dia={item.dia}
                                                                status={item.status}
                                                                horario_inicial={item.horario_inicial_1}
                                                                horario_final={item.horario_final_1}
                                                                retornoInformacoesDia={(novoStatus, novoHorarioInicial, novoHorarioFinal) =>
                                                                    atualizarDia(index, novoStatus, novoHorarioInicial, novoHorarioFinal)
                                                                }
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-9'></div>
                                                <div className='col-sm-3'>
                                                    <ButtonCustom
                                                        onClick={() => {
                                                            alterarHorarios();
                                                        }}
                                                        color={"error"}
                                                        texto={"Atualizar horários"}
                                                        icon={null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Finalizar atendimentos por dia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <div className='row'>
                                                <div className='col-sm-3'>
                                                    <h6>Defina um dia</h6>
                                                    <Input
                                                        value={diaFinalizar}
                                                        type="date"
                                                        style={inputForm}
                                                        onChange={(event) => {
                                                            event.preventDefault();
                                                            setDiaFinalizar(event.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className='row'>
                                                <div className='col-sm-9'></div>
                                                <div className='col-sm-3'>
                                                <ButtonCustom
                                                        onClick={() => {
                                                            finalizarAtendimentoPorDia();
                                                        }}
                                                        color={"error"}
                                                        texto={"Finalizar atendimentos"}
                                                        icon={null}
                                                    />
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>{textoSincContato}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>

                                            <div className='row'>
                                                <div className='col-sm-8'>
                                                    {sincronizandoCtt ? (
                                                        <span>
                                                            <LinearProgress />
                                                        </span>
                                                    ) : (
                                                        <span></span>
                                                    )}
                                                    {alertSuccessSincContato ? (
                                                        <Alert severity="success">Contatos sincronizados com sucesso!</Alert>
                                                    ) : (
                                                        <span></span>
                                                    )}
                                                    {alertErroSincContato ? (
                                                        <Alert severity="error">Ocorreu um erro ao sincronizar os contatos!</Alert>
                                                    ) : (
                                                        <span></span>
                                                    )}
                                                </div>
                                                <div className='col-sm-4'>

                                                </div>
                                                <div className='col-sm-9'></div>
                                                <div className='col-sm-3'>
                                                <ButtonCustom
                                                        onClick={() => {
                                                            sincronizarContatos();
                                                        }}
                                                        color={"error"}
                                                        texto={"Sincronizar contatos"}
                                                        icon={null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>{'Conexão Whatsapp'}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {statusConexaoW ? (
                                                <>
                                                    <Button
                                                        onClick={() => {
                                                            DesconectarSessaoW();
                                                            StatusSessaoW();
                                                        }}
                                                        style={buttonWhiteForm}>
                                                        Desconectar sessão
                                                    </Button>
                                                    <br /> <br />
                                                    <Alert severity="success">
                                                        Sua conexão com o Whatsapp está ativa.
                                                    </Alert>
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        onClick={() => {
                                                            InicarSessaoW();
                                                            StatusSessaoW();
                                                        }}
                                                        style={buttonWhiteForm}>
                                                        Iniciar sessão
                                                    </Button>
                                                    <br /> <br />
                                                    {qrCodeConexaoW ? (
                                                        <img alt='conectar' src={qrCodeConexaoW} />
                                                    ) : null}

                                                </>
                                            )}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion
                                    onClick={() => {
                                        navigate({ pathname: '/dias_nao_atendidos' })
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>{'Dias não atendidos'}</Typography>
                                    </AccordionSummary>
                                </Accordion>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
