import React from 'react';
import { MenuSide } from '../../components/MenuSide';
import GraficosAtendimentosHorarios from './GraficosAtendimentosHorarios';
import GraficosMensagensSemanaPassada from './GraficoMensagensSemanaPassada';
import GraficosMensagensSemanaAtual from './GraficoMensagensSemanaAtual';
import GraficoContatosEmpresas from './GraficosContatosEmpresas';

export default function Atendimentos() {

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>

                    <br /> <div className='card'
                        style={{ marginRight: '1%', }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-10' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Métricas
                                </div>
                                <div className='col-sm-2 text-end'>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='card'
                                style={{ marginRight: '30px', }}>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-sm-10' style={{ fontWeight: 700, fontSize: '25px', }}>
                                            Quantidade de mensagens por horário
                                        </div>
                                        <div className='col-sm-2 text-end'>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div style={{ padding: 20 }}>
                                        <GraficosAtendimentosHorarios />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    <div className='row'>

                    </div>

                    <br />
                    <div className='row'>
                        <div className='col-sm-5'>
                            <div className='card'
                                style={{ marginLeft: '30px', marginRight: '30px', }}>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-sm-10' style={{ fontWeight: 700, fontSize: '25px', }}>
                                            Quantidade de contatos por empresa
                                        </div>
                                        <div className='col-sm-2 text-end'>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div style={{ padding: 20 }}>
                                        <GraficoContatosEmpresas />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='card'
                                style={{ marginLeft: '30px', }}>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-sm-12' style={{ fontWeight: 700, fontSize: '25px', }}>
                                            Mensagens da semana anterior
                                        </div>

                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div style={{ padding: 20 }}>
                                        <GraficosMensagensSemanaPassada />
                                    </div>
                                </div>
                            </div>
                            <div className='card'
                                style={{ marginLeft: '30px', marginTop: 30, }}>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-sm-12' style={{ fontWeight: 700, fontSize: '25px', }}>
                                            Mensagens da semana atual
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div style={{ padding: 20 }}>
                                        <GraficosMensagensSemanaAtual />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>

                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
        </>
    );
}
