import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { TableDados } from './components/TableDados.tsx';
import InputCustom from '../../components/InputCustom';
import SelectCustom from '../../components/SelectCustom.jsx';
import ButtonCustom from '../../components/ButtonCustom';
import IconCancelar from '@mui/icons-material/DoDisturb';
import { ModalRecusarPedido } from './components/ModalRecusar.jsx';

export default function Pedidos({ clienteId, nomeCliente }) {

    const navigate = useNavigate();
    const [filtro, setFiltro] = useState(null);
    const [status, setStatus] = useState(1);
    const [inicio, setInicio] = useState(null);
    const [fim, setFim] = useState(null);
    const [modalRecusa, setModalRecusa] = useState(false);

    const statusTextArray = [
        { id: "1", nome: "Pendente - Em preparação" },
        { id: "2", nome: "Em andamento" },
        { id: "3", nome: "Aguardando confirmação de pagamento" },
        { id: "4", nome: "Finalizado" },
        { id: "5", nome: "Cancelado" },
        { id: "6", nome: "Reembolsado" },
        { id: "7", nome: "No carrinho" },
    ];

    return (
        <>
            <ModalRecusarPedido isOpen={modalRecusa} setIsOpen={() => setModalRecusa(false)} />
            <div className='row'>

                {!clienteId ? (
                    <div className='col-sm-2'>
                        <MenuSide />
                    </div>
                ) : null}

                <div className={!clienteId ? 'col-sm-10' : 'col-sm-12'} >
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className={!clienteId ? 'col-sm-3' : 'col-sm-8'} style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Pedidos {nomeCliente}
                                </div>
                                {!clienteId ? (
                                    <div className='col-sm-5'></div>
                                ):null}
                                {!clienteId ? (
                                    <React.Fragment>
                                        <div className='col-sm-2 text-end'>
                                            <ButtonCustom
                                                onClick={() => {
                                                    setModalRecusa(true);
                                                }}
                                                color={"warning"}
                                                texto={"Recusar"}
                                                icon={IconCancelar}
                                            />
                                        </div>
                                        <div className='col-sm-2 text-end'>
                                            <ButtonCustom
                                                onClick={() => {
                                                    navigate({
                                                        pathname: "/pedidos/adicionar",
                                                    });
                                                }}
                                                color={"error"}
                                                texto={"Adicionar"}
                                                icon={null}
                                            />
                                        </div></React.Fragment>
                                ) : null}

                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                {!clienteId ? (
                                    <div className='col-sm-3'>
                                        <h6>Nome do cliente/Empresa</h6>
                                        <InputCustom
                                            value={filtro}
                                            setValue={(event) => {
                                                event.preventDefault();
                                                setFiltro(event.target.value)
                                            }}
                                            type={'text'}
                                            placeHolder={'Nome do cliente/Empresa'}
                                        />
                                    </div>
                                ) : null}
                                <div className='col-sm-3'>
                                    <h6>Status</h6>
                                    <SelectCustom
                                        value={status}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setStatus(event.target.value);
                                        }}
                                        itens={statusTextArray}
                                        valueItem={'id'}
                                        nameItem={'nome'}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Data inicial</h6>
                                    <InputCustom
                                        value={inicio}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setInicio(event.target.value)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Data final</h6>
                                    <InputCustom
                                        value={fim}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setFim(event.target.value)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                            </div>
                            <br />
                            <TableDados clienteId={clienteId} nome={filtro} status={status} inicio={inicio} fim={fim} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
