/* eslint-disable react/no-unknown-property */
import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import axios from "axios";
import {
    Button, CircularProgress, Paper
} from '@mui/material';
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { tokenUsuario, urlRelatorioPedidosPorFormaPagamento } from '../../utils/constants';
import { MenuSide } from '../../components/MenuSide';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import FilterListIcon from '@mui/icons-material/FilterList';
import html2pdf from 'html2pdf.js';
import InputCustom from '../../components/InputCustom';

export default function RelatorioPedidosFormaPagamento() {

    const filtrarInformacoes = async () => {
        setLoading(true);
        try {
            const response = await axios.post(urlRelatorioPedidosPorFormaPagamento, {
                token: tokenUsuario,
                dataInicial: dataInicial,
                dataFinal: dataFinal
            })
            setPedidos(response.data.pedidos);
            setTotalItens(response.data.total_itens);
            setTotalSoma(response.data.total_soma);
            setItens(response.data.itens);
            setItensContagem(response.data.itens_contagem);
            setItensContagemTamanho(response.data.itens_contagem_tamanho)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const exportarParaExcel = (event) => {
        event.preventDefault();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const formattedPedidos = pedidos.map(pedido => {

            const itemDetails = itens
                .filter(item => pedido.forma_pagamento_id == item.formas_de_pagamento)
                .map(item => `${pedido.forma_pagamento} - ${item.nome_produto} = ${item.total_itens} itens`)
                .join('; ');

            const totalMarmitas = itensContagem
                .filter(item => pedido.forma_pagamento_id == item.forma_pagamento_id)
                .map(item => `${pedido.forma_pagamento} - Total de marmitas: ${item.total_itens}`)
                .join('; ');

            return {
                'Detalhes dos Itens': itemDetails,
                'Total de Marmitas': totalMarmitas,
                'Período de Datas': `${dataInicial} / ${dataFinal}`,
                'Total Soma': `R$ ${pedido.total_soma}`
            };
        });

        // Convert the formatted data to an Excel sheet
        const ws = XLSX.utils.json_to_sheet(formattedPedidos);


        const wb = { Sheets: { 'pedidos': ws }, SheetNames: ['pedidos'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'relatorio_pedidos' + fileExtension);
    }

    function addZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const exportarParaPDF = async () => {
        const element = componentRef.current;
        let date = new Date();
        const dia = addZero(date.getDate());
        const mes = addZero(date.getMonth() + 1);
        const ano = date.getFullYear();
        const hora = addZero(date.getHours());
        const minuto = addZero(date.getMinutes());
        const segundo = addZero(date.getSeconds());

        const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;

        const pdfOptions = {
            margin: 10,
            filename: `relatorio_pedidos_forma_pagamento_${dataInicial}_${dataFinal}_gerado_${dataFormatada}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        setProgressOpen(true);
        await html2pdf().from(element).set(pdfOptions).save();
        setProgressOpen(false);
    };

    const formatarItens = (itens) => {
        const tamanhoMapa = {
            'M': 'Mini',
            'G': 'Grande',
            'E': 'Executiva'
        };

        return itens.map(item => {
            const textoTamanho = tamanhoMapa[item.tamanho_produto] || 'Não informado/Adicionais';
            return `${textoTamanho}: ${item.total_itens}`;
        }).join(', ');
    }


    const [progressOpen, setProgressOpen] = useState(false);

    const componentRef = useRef();
    const [loading, setLoading] = useState(0);
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [pedidos, setPedidos] = useState([]);
    const [itens, setItens] = useState([]);
    const [itensContagem, setItensContagem] = useState([]);
    const [itensContagemTamanho, setItensContagemTamanho] = useState([]);
    const [totalItens, setTotalItens] = useState(0);
    const [totalSoma, setTotalSoma] = useState(0);

    return (
        <>

            <Dialog open={progressOpen} onClose={() => setProgressOpen(false)}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <DialogTitle>Gerando PDF...</DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>

            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-12' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Relatório de Marmitas Por Forma de Pagamento
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-2'>
                                    <h6>Data inicial</h6>
                                    <InputCustom
                                        value={dataInicial}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setDataInicial(event.target.value)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Data final</h6>
                                    <InputCustom
                                        value={dataFinal}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setDataFinal(event.target.value)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <br />
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            filtrarInformacoes();
                                        }}
                                        style={{
                                            width: '100%',
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        BUSCAR  &nbsp;&nbsp; <FilterListIcon style={{ fontSize: 16 }} />
                                    </Button>
                                </div>
                                <div className='col-sm-1'>
                                    <br />
                                    <Button
                                        variant='contained'
                                        color={'error'}
                                        onClick={() => {
                                            exportarParaPDF();
                                        }}
                                        style={{
                                            width: '100%',
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        <PictureAsPdfIcon style={{ fontSize: 16 }} />
                                    </Button>
                                </div>
                                <div className='col-sm-1'>
                                    <br />
                                    <a
                                        id="downloadLink"
                                        onClick={exportarParaExcel}
                                    >
                                        <Button
                                            variant='contained'
                                            color={'success'}

                                            style={{
                                                width: '100%',
                                                height: 39,
                                                borderRadius: '0.5rem',
                                                marginTop: '4px',
                                            }}
                                        >
                                            <ArticleIcon style={{ fontSize: 16 }} />
                                        </Button>
                                    </a>
                                </div>
                                <div className='col-sm-1'>
                                    <br />
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button
                                                variant='contained'
                                                color='info'
                                                style={{
                                                    width: '100%',
                                                    height: 39,
                                                    borderRadius: '0.5rem',
                                                    marginTop: '4px',
                                                }}
                                            >
                                                <span><LocalPrintshopIcon style={{ fontSize: 16 }} /></span>
                                            </Button>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                </div>
                            </div>

                            <br />

                            <div id='imprimir' ref={componentRef}>

                                {loading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '20%' }}>
                                        <CircularProgress color="primary" />
                                    </div>
                                ) : (
                                    <>
                                        <div className="containerFaixaRelatorio">

                                            <div className="itemImagemFaixaRelatorio">
                                                <img src="https://sistema-marmita-express.gerenciadores.com.br/logo192.png" className="ImagemFaixaRelatorio" alt="" />
                                            </div>

                                            <div className="itemFaixaRelatorio">
                                                &nbsp;&nbsp;MARMITA EXPRESS<br />
                                                <h6 className="descricaoItemRelatorio" style={{ marginTop: 10 }}>&nbsp;&nbsp;&nbsp;&nbsp;Relatório de Marmitas Por Forma de Pagamento</h6>
                                            </div>

                                        </div>
                                        <div component={Paper}>
                                            <table className='table table' sx={{ minWidth: 650 }} aria-label="simple table">
                                                <thead>
                                                    <tr>
                                                        <th>Forma de pagamento</th>
                                                        <th>Período</th>
                                                        <th>Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pedidos.map((pedido, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {itens.filter(item => pedido.forma_pagamento_id == item.formas_de_pagamento).map((item, itemIndex) => (
                                                                    <div key={itemIndex}>
                                                                        {item.nome_produto} = {item.total_itens} itens
                                                                    </div>
                                                                ))}
                                                                <br />
                                                                <div>{pedido.forma_pagamento}
                                                                    {itensContagem.filter(item => pedido.forma_pagamento_id == item.forma_pagamento_id).map((item, itemIndex) => (
                                                                        <div key={itemIndex}>
                                                                            Total de marmitas: {item.total_itens}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </td>
                                                            <td>{dataInicial} / {dataFinal}</td>
                                                            <td>R$ {pedido.total_soma}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='text-end'>
                                            <span>Itens por tamanho - {formatarItens(itensContagemTamanho)}</span> &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>Total de marmitas: {totalItens}</span>
                                            &nbsp;&nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>R$ {totalSoma}</span>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
