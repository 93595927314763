import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Box,
  FormControl,
  Input,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import {
  urlAdicionaEditaDiasNaoAtendidos,
  tokenUsuario,
  urlInformacoesDiasNaoAtendidos,
} from "../../../utils/constants";
import axios from "axios";
import { AlertCustom } from "../../../components/AlertCustom";
import { inputForm } from "../../../styles/style";
import ButtonCustom from "../../../components/ButtonCustom";

export const ModalData = ({ isOpen, setIsOpen, data }) => {
  const [successAlert, setSuccessAlert] = useState(false);
  const [erroAlert, setErrorAlert] = useState(false);
  const [bloqDes, setBloqDes] = useState(false);
  const [dataSelecionada, setDataSelecionada] = useState(data);
  const [title, setTitle] = useState("Bloqueado");

  const carregarDiaNaoAtendido = async () => {
    try {
      const response = await axios.post(urlInformacoesDiasNaoAtendidos, {
        token: tokenUsuario,
        date: dataSelecionada,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const listarDiaNaoAtendido = async () => {
    setDataSelecionada(data);
    const dados = await carregarDiaNaoAtendido();
    if (dados == false) {
      setBloqDes(true);
      setTitle("");
    } else {
      setBloqDes(false);
      setTitle(dados.title);
    }
  };

  const bloquerDesbloquearData = async () => {
    try {
      const response = await axios.post(urlAdicionaEditaDiasNaoAtendidos, {
        token: tokenUsuario,
        title: title,
        date: dataSelecionada,
      });
      if (response) {
        setSuccessAlert(true);
        listarDiaNaoAtendido();
        setIsOpen();
      }
    } catch (error) {
      setErrorAlert(true);
      listarDiaNaoAtendido();
      setIsOpen();
      return null;
    }
  };

  const fecharSucessAlert = () => {
    setSuccessAlert(false);
  };

  const fechaErrorAlert = () => {
    setErrorAlert(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    listarDiaNaoAtendido();
  }, [data, dataSelecionada]);

  return (
    <>
      <div>
        <AlertCustom
          texto={"Alterações efetuadas com sucesso!"}
          color="success"
          isOpen={successAlert}
          setIsOpen={fecharSucessAlert}
          tempo={2000}
        />
        <AlertCustom
          texto={"Não foi possível efetuar as alterações!"}
          color="error"
          isOpen={erroAlert}
          setIsOpen={fechaErrorAlert}
          tempo={3000}
        />

        <Modal open={isOpen} onClose={setIsOpen}>
          <Box style={{ padding: "4% 37% 10% 37%" }}>
            <Card
              style={{
                maxHeight: "85vh",
                minHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <IconButton onClick={handleClose}>
                  <CloseIcon
                    style={{
                      margin: "10px",
                    }}
                  />
                </IconButton>
              </div>
              <>
                <div style={{ margin: "20px" }}>
                  <h5 style={{ textAlign: "center", color: "#fff" }}>
                    Bloquear/Desbloquear data
                  </h5>
                  <div className="row">&nbsp;</div>
                  <FormControl fullWidth>
                    <Input
                      placeholder="Data"
                      onChange={(event) => {
                        event.preventDefault();
                        setDataSelecionada(event.target.value);
                      }}
                      readOnly={true}
                      value={dataSelecionada}
                      type="date"
                      style={inputForm}
                    />
                  </FormControl>
                  <div className="row">&nbsp;</div>
                  <ButtonCustom
                    onClick={() => {
                      bloquerDesbloquearData();
                    }}
                    color={"error"}
                    texto={bloqDes ? "Bloquear" : "Desbloquear"}
                    icon={CachedIcon}
                  />
                </div>
              </>
            </Card>
          </Box>
        </Modal>
      </div>
    </>
  );
};
