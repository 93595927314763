import React from "react";
import { FormControl, TextField } from "@mui/material";

export default function InputCustom({ value, setValue, type, placeHolder, onkeydown }) {
  return (
    <FormControl fullWidth>
      <TextField
        placeholder={placeHolder}
        onChange={setValue}
        value={value}
        type={type}
        onKeyDown={onkeydown}
        InputProps={{
          sx: {
            height: "39px",
            borderRadius: "0.5rem",
            width: "100%",
            padding: "10px",
          },
        }}
        variant="outlined"
      />
    </FormControl>
  );
}
