import React, { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  Card,
  Modal,
  Box,
  IconButton,
  ListItemText,
  ListItemButton,
  List,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ModalDialog } from "../components/ModalDialog";
import {
  urlBuscarContatos,
  urlIniciarAtendimentoManual,
  tokenUsuario,
} from "../../../utils/constants";
import axios from "axios";
import { AlertCustom } from "../../../components/AlertCustom";
import InputCustom from "../../../components/InputCustom";

export const ModalListaContatos = ({
  isOpen,
  setIsOpen,
  atualizarMensagemNumeroAtual,
}) => {
  let navigate = useNavigate();

  const [contatos, setContatos] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [statusDialog, setStatusDialog] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [erroAlert, setErrorAlert] = useState(false);

  const carregarContatos = async () => {
    try {
      const response = await axios.post(urlBuscarContatos, {
        token: tokenUsuario,
        nome: filtro,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const listarContatos = async () => {
    let lista = await carregarContatos();
    setContatos(lista);
  };

  const iniciarAtendimento = async (numero) => {
    try {
      const response = await axios.post(urlIniciarAtendimentoManual, {
        numero: numero,
        token: tokenUsuario,
      });
      if (response.data.status == "iniciado") {
        setIsOpen();
        setSuccessAlert(true);
        atualizarMensagemNumeroAtual(numero);
        navigate({
          pathname: "/chat",
          search: createSearchParams({
            numero: numero,
          }).toString(),
        });
      } else {
        setErrorAlert(true);
      }
    } catch (error) {
      return null;
    }
  };

  const fecharSucessAlert = () => {
    setSuccessAlert(false);
  };

  const fechaErrorAlert = () => {
    setErrorAlert(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    listarContatos();
  }, [filtro]);

  return (
    <>
      <div>
        <AlertCustom
          texto={"Atendimento iniciado com sucesso"}
          color="success"
          isOpen={successAlert}
          setIsOpen={fecharSucessAlert}
          tempo={2000}
        />
        <AlertCustom
          texto={" Não foi possível iniciar o atendimento!"}
          color="error"
          isOpen={erroAlert}
          setIsOpen={fechaErrorAlert}
          tempo={3000}
        />

        <Modal open={isOpen} onClose={setIsOpen}>
          <Box style={{ padding: "4% 37% 10% 37%" }}>
            <Card
              style={{
                maxHeight: "85vh",
                minHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <IconButton onClick={handleClose}>
                  <CloseIcon
                    style={{
                      margin: "10px",
                    }}
                  />
                </IconButton>
              </div>
              <>
                <ModalDialog
                  isOpen={statusDialog}
                  setIsOpen={setStatusDialog}
                />
                <div style={{ margin: "20px" }}>
                  <h5 className="text-center">Contatos</h5>
                  <div className="row">&nbsp;</div>
                  <InputCustom
                    value={filtro}
                    setValue={(event) => {
                      event.preventDefault();
                      setFiltro(event.target.value);
                      console.log(event.target.value)
                    }}
                    type={"text"}
                    placeHolder="Nome do contato"
                  />
                  <div className="row">&nbsp;</div>

                  <List>
                    {contatos.map((item, index) => (
                      <React.Fragment key={index}>
                        <ListItemButton
                          onClick={() => {
                            iniciarAtendimento(item.numero);
                          }}
                          style={{ borderBottom: "1px solid #2A3942" }}
                          component="a"
                          href="#simple-list"
                        >
                          <ListItemText primary={item.nome} />
                        </ListItemButton>
                      </React.Fragment>
                    ))}
                  </List>
                </div>
              </>
            </Card>
          </Box>
        </Modal>
      </div>
    </>
  );
};
