import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import {
  urlMensagensAguardandoAtendimento,
  tokenUsuario,
} from "../../../utils/constants";
import CustomPagination from "../../../components/PaginationTable";
import InputCustom from "../../../components/InputCustom";

export function converterDataFormato(dataOriginal) {
  if (dataOriginal === null) {
    return;
  }
  //@ts-ignore
  const partes = dataOriginal.split(" ");
  const dataPartes = partes[0].split("-");
  const horaPartes = partes[1].split(":");

  const dia = dataPartes[2];
  const mes = dataPartes[1];
  const ano = dataPartes[0];

  const hora = horaPartes[0];
  const minutos = horaPartes[1];

  const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minutos}`;

  return dataFormatada;
}

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const TableAguardando = ({
  countagemMensagens,
  retAtendimento,
  retNumero,
  retStatusDialog,
  retNome,
}) => {
  const [contatos, setAtendimentos] = useState([]);
  const [countMsg, setCountMsg] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pagina, setPagina] = useState(1);
  const [nome, setNome] = useState("");
  const debouncedNome = useDebounce(nome, 500);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams2 = new URLSearchParams(location.search);

  const listarAtendimentos = async () => {
    try {
      const response = await axios.post(
        `${urlMensagensAguardandoAtendimento}?pagina=${pagina}`,
        {
          token: tokenUsuario,
          nome: nome,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const listarMensagens = async () => {
    let mensagens = await listarAtendimentos();
    setAtendimentos(mensagens);
  };

  useEffect(() => {
    setCountMsg(countagemMensagens);
    if (countagemMensagens != countMsg) {
      listarMensagens();
    }
  }, [countagemMensagens, countMsg]);

  const alterarContato = (event) => {
    retAtendimento(event.row.id);
    retNumero(event.row.numero);
    retStatusDialog(true);
    retNome(event.row.nome_contato);
  };

  const handlePageChange = (newPage) => {
    setPagina(newPage);
    listarAtendimentos(newPage)
      .then((lista) => {
        const contatosFiltrados = lista;
        setAtendimentos(contatosFiltrados);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarAtendimentos();
      setAtendimentos(lista);
      searchParams2.set("pagina", pagina);
      const novaURL = `${location.pathname}?${searchParams2.toString()}`;
      navigate(novaURL);
      setLoading(false);
    };
    run();
  }, [pagina, debouncedNome]);

  const cellData = (data) => {
    return <span>{converterDataFormato(data)}</span>;
  };

  const columns = [
    { field: "id", headerName: "#", width: 220 },
    { field: "nome_contato", headerName: "Nome", width: 220 },
    { field: "numero", headerName: "Número", width: 220 },
    {
      field: "data",
      headerName: "Data",
      renderCell: (params) => cellData(params.value),
      width: 300,
    },
  ];

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="col-sm-3">
          <h6>Buscar por nome</h6>
          <InputCustom
            value={nome}
            setValue={(event) => {
              event.preventDefault();
              setNome(event.target.value);
            }}
            type={"text"}
            placeHolder={"Buscar por nome"}
          />
          <br/> <br/>
        </div>
        {loading ? (
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        ) : (
          <div>
            <DataGrid
              rows={contatos}
              columns={columns}
              disableSelectionOnClick
              disableColumnMenu
              disableColumnSelector
              disableRowSelectionOnClick
              rowHeight={40}
              headerHeight={40}
              autoHeight={true}
              onRowClick={alterarContato}
              getRowId={(row) => row.id}
              components={{
                Pagination: (props) => (
                  <CustomPagination
                    {...props}
                    onPageChange={handlePageChange}
                  />
                ),
              }}
              sx={{
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
