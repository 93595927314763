export const inputForm = {
    height: "39px",
    borderRadius: '0.5rem',
    width: '100%',
    padding: '10px'
};

export const selectInputForm = {
    PaperProps: {
        style: {
            backgroundColor: "#2A3942",
            color: "#DFF3ED",
            borderRadius: '0.5rem',
        },
    },
};

export const buttonWhiteForm = {
    width: '100%',
    borderRadius: '0.5rem',
    height: '40px',
};