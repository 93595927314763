/* eslint-disable no-unused-vars */
import React from "react";
import {
  Chip,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";

function primeiraLetraPrimeiroEUltimoNome(nomeCompleto) {
  const palavras = nomeCompleto.split(" ");

  if (palavras.length >= 2) {
    const primeiraLetraPrimeiroNome = palavras[0].charAt(0).toUpperCase();
    const ultimoNome = palavras[palavras.length - 1];
    const primeiraLetraUltimoNome = ultimoNome.charAt(0).toUpperCase();

    const corAleatoria =
      "#" + Math.floor(Math.random() * 16777215).toString(16);

    return [primeiraLetraPrimeiroNome + primeiraLetraUltimoNome, corAleatoria];
  } else {
    return null;
  }
}

export const ItemChatLista = ({ nome, mensagens }) => {
  let countMensagens = mensagens;
  let alertCountMensagens = "";
  if (mensagens > 1) {
    alertCountMensagens = mensagens;
  } else {
    alertCountMensagens = mensagens;
  }
  let abreviado = primeiraLetraPrimeiroEUltimoNome(nome);
  let nomeAbreviado = "";
  if (abreviado) {
    nomeAbreviado = abreviado[0];
  }

  return (
    <>
      <ListItemButton>
        <ListItemAvatar>
          <div style={{ flex: "20%", justifyContent: "center" }}>
            <div
              style={{
                height: 40,
                width: 40,
                borderRadius: "0.5rem",
                background: "#D24245",
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {nomeAbreviado}
            </div>
          </div>
        </ListItemAvatar>
        <ListItemText primary={nome} />
      <div>
      {countMensagens > 0 ? (
          <Chip label={alertCountMensagens} color="error" size="small" />
        ) : (
          <span></span>
        )}
      </div>
      </ListItemButton>
    </>
  );
};
