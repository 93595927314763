import React, { useState, useRef } from 'react';
import { urlCodigoVerificacao } from '../../utils/constants';
import axios from 'axios';
import { Button, CircularProgress, FormControl, TextField } from '@mui/material';
import { AlertCustom } from "../../components/AlertCustom";
import GppGoodIcon from '@mui/icons-material/GppGood';
import { buttonWhiteForm } from '../../styles/style';
import Cookies from 'universal-cookie';

export default function CodigoVerificacao() {

    const [codigo1, setCodigo1] = useState('');
    const [codigo2, setCodigo2] = useState('');
    const [codigo3, setCodigo3] = useState('');
    const [codigo4, setCodigo4] = useState('');
    const [codigo5, setCodigo5] = useState('');
    const [codigo6, setCodigo6] = useState('');

    const [loading, setLoading] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [erroAlert, setErrorAlert] = useState(false);

    const codigoRefs = [
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ];

    const fecharSucessAlert = () => {
        setSuccessAlert(false);
    };

    const fechaErrorAlert = () => {
        setErrorAlert(false);
    };

    const handleCodigoChange = (e, index) => {
        let value = e.target.value;

        if (value.length > 1) {
            value = value.slice(0, 1);
        }

        switch (index) {
            case 0:
                setCodigo1(value);
                break;
            case 1:
                setCodigo2(value);
                break;
            case 2:
                setCodigo3(value);
                break;
            case 3:
                setCodigo4(value);
                break;
            case 4:
                setCodigo5(value);
                break;
            case 5:
                setCodigo6(value);
                break;
            default:
                break;
        }

        if (value.length === 1 && index < 5) {
            codigoRefs[index + 1].current.focus();
        }
    };

    const verificarCodigo = async () => {
        const cookies = new Cookies();
        const codigoEnviar = `${codigo1}${codigo2}${codigo3}${codigo4}${codigo5}${codigo6}`;
        try {
            const response = await axios.post(urlCodigoVerificacao, {
                codigo: codigoEnviar,
            })
            if (response.data.status == '1') {
                const umAnoEmMillisegundos = 365 * 24 * 60 * 60 * 1000;
                const cookieOptions = {
                    secure: true,
                    httpOnly: false,
                    path: '/',
                    expires: new Date(new Date().getTime() + umAnoEmMillisegundos)
                };
                cookies.set('token', response.data.token, cookieOptions);
                setSuccessAlert(true);
                setLoading(false);
                window.location.href = '/inicio/';
            } else {
                setErrorAlert(true);
                setLoading(false);
            }
        } catch (error) {
            return null;
        }
    }

    return (
        <>
            <AlertCustom
                texto={"Login efetuado com sucesso, aguarde..."}
                color="success"
                isOpen={successAlert}
                setIsOpen={fecharSucessAlert}
                tempo={2000}
            />
            <AlertCustom
                texto={"Dados inválidos"}
                color="error"
                isOpen={erroAlert}
                setIsOpen={fechaErrorAlert}
                tempo={3000}
            />
            <div>
                <div className='row'>
                    <div className='col-sm-4'></div>
                    <div className='col-sm-4'>
                        <div className='card' style={{ borderRadius: '0.5rem', marginTop: '20vh', padding: '20px' }}>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <h6>Informe o código de verificação enviado para o Whatsapp do Marmita Express</h6>
                            </div>

                            <br />

                            <div className='row'>
                                {codigoRefs.map((ref, index) => (
                                    <div className='col-2' key={index}>
                                        <FormControl fullWidth>
                                            <TextField
                                                placeholder=''
                                                value={index === 0 ? codigo1 : index === 1 ? codigo2 : index === 2 ? codigo3 : index === 3 ? codigo4 : index === 4 ? codigo5 : codigo6}
                                                onChange={(e) => handleCodigoChange(e, index)}
                                                type="text"
                                                inputRef={ref}
                                                style={{
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                InputProps={{
                                                    sx: {
                                                        display: 'flex',
                                                        textAlign: 'center',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: "70px",
                                                        borderRadius: '0.5rem',
                                                        width: '100%',
                                                        fontSize: 30,
                                                        fontWeight: 800,
                                                    }
                                                }}
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </div>
                                ))}
                            </div>

                            <br />

                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '20%' }}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : (
                                <Button
                                    variant={'contained'}
                                    color={'error'}
                                    onClick={() => {
                                        verificarCodigo();
                                    }}
                                    style={buttonWhiteForm}>
                                    Confirmar &nbsp;&nbsp;&nbsp;<GppGoodIcon />
                                </Button>
                            )}

                        </div>
                    </div>
                    <div className='col-sm-4'></div>
                </div>
            </div>
        </>
    );
}
