import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function CardAtalho({ color, text, value, Icon, rota }) {
  const navigate = useNavigate();

  return (
    <div
      style={{ cursor: "pointer" }}
      className="card"
      onClick={() => {
        navigate({ pathname: rota });
      }}
    >
      <div style={{ margin: 20 }}>
        <h5 style={{ fontWeight: 400, fontSize: "12px" }}>{text}</h5>
        <h1 style={{ fontWeight: 900, marginTop: 10, fontSize: "30px" }}>
          {value}
        </h1>
        <div
          style={{
            position: "absolute",
            bottom: "1%",
            left: "82%",
            marginBottom: 15,
          }}
        >
          <div
            style={{
              backgroundColor: color,
              color: "#fff",
              borderRadius: "0.5rem",
              height: "37px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {Icon ? <Icon style={{ fontSize: "14px" }} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
