import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import HomeIcon from "@mui/icons-material/Home";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import GroupIcon from "@mui/icons-material/Group";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import themeLightIcon from "@mui/icons-material/LightMode";
import themeDarkIcon from "@mui/icons-material/ModeNight";
import SideItem from "./SideItem";
import { ModalRelatorios } from "./ModalRelatorios";
import { ModalCadastros } from "./ModalCadastro";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ForumIcon from "@mui/icons-material/Forum";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import BarChartIcon from "@mui/icons-material/BarChart";
import MenuIcon from "@mui/icons-material/Menu";
import { ItemMenuSide } from "./ItemMenuSide";

export const MenuSide = () => {
  const theme = localStorage.getItem("theme");
  const [larguraDaPagina, setLarguraDaPagina] = useState(window.innerWidth);
  const [classMenu, setClassMenu] = useState("collapse show");

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove("token");
    window.location.href = "/";
  };

  const [modalRelatorio, setModalRelatorio] = useState(false);
  const [modalCadastro, setModalCadastro] = useState(false);

  const verificarRotaCadastro = (rota) => {
    if (
      rota == "produtos" ||
      rota == "categorias" ||
      rota == "ingredientes" ||
      rota == "forma_pagamentos" ||
      rota == "clientes" ||
      rota == "empresas"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const closeModalRelatorio = () => {
    setModalRelatorio(false);
    setModalCadastro(false);
  };

  const modalRelatorios = () => {
    setModalRelatorio(true);
  };

  const modalCadastros = () => {
    setModalCadastro(true);
  };

  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location.pathname;

  const partesDoCaminho = pathname.split("/");
  const primeiroNomeDoCaminho = partesDoCaminho[1];

  const [collapseW, setCollapseW] = useState(
    sessionStorage.getItem("collapseW") === "true"
  );

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaPagina(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 584) {
      setClassMenu("collapse");
    }
  }, []);

  return (
    <>
      <ModalRelatorios
        modalRelatorio={modalRelatorio}
        closeModalRelatorio={closeModalRelatorio}
      />
      <ModalCadastros
        modalCadastro={modalCadastro}
        closeModalRelatorio={closeModalRelatorio}
      />
      {larguraDaPagina <= 584 ? (
        <div style={{ margin: 5 }}>
          <button
            style={{
              width: "60px",
              height: "39px",
              marginLeft: "5px",
              marginTop: "5px",
            }}
            className="btn btn-danger"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <MenuIcon style={{ fontSize: "16px" }} />
          </button>
        </div>
      ) : null}
      <div className={classMenu} id="collapseExample">
        <div
          className={
            theme === "dark" ? "sideBarBorderDark" : "sideBarBorderLight"
          }
        >
          <div className="row">&nbsp;</div>
          <div className="menuSide">
            <SideItem
              funcao={() => {
                navigate({ pathname: "/inicio" });
              }}
              backgroundColor={
                primeiroNomeDoCaminho === "inicio" ? "#D24245" : "transparent"
              }
              color={primeiroNomeDoCaminho === "inicio" ? "#ffffff" : "#000000"}
              icon={HomeIcon}
              title="Início"
            />

            <SideItem
              funcao={() => {
                navigate({ pathname: "/pedidos" });
              }}
              backgroundColor={
                primeiroNomeDoCaminho === "pedidos" ? "#D24245" : "transparent"
              }
              color={
                primeiroNomeDoCaminho === "pedidos" ? "#ffffff" : "#000000"
              }
              icon={ConfirmationNumberIcon}
              title="Pedidos"
            />

            <SideItem
              funcao={() => {
                modalCadastros();
              }}
              backgroundColor={
                verificarRotaCadastro(primeiroNomeDoCaminho)
                  ? "#D24245"
                  : "transparent"
              }
              color={
                verificarRotaCadastro(primeiroNomeDoCaminho)
                  ? "#ffffff"
                  : "#000000"
              }
              icon={AddOutlinedIcon}
              title="Cadastros"
            />

            <SideItem
              funcao={() => {
                modalRelatorios();
              }}
              backgroundColor={
                primeiroNomeDoCaminho.includes("relatorios")
                  ? "#D24245"
                  : "transparent"
              }
              color={
                primeiroNomeDoCaminho.includes("relatorios")
                  ? "#ffffff"
                  : "#000000"
              }
              icon={AssessmentOutlinedIcon}
              title="Relatórios"
            />

            <SideItem
              funcao={() => {
                navigate({ pathname: "/avancado" });
              }}
              backgroundColor={
                primeiroNomeDoCaminho === "avancado" ? "#D24245" : "transparent"
              }
              color={
                primeiroNomeDoCaminho === "avancado" ? "#ffffff" : "#000000"
              }
              icon={SettingsSuggestIcon}
              title="Avançado"
            />

            <SideItem
              funcao={() => {
                navigate({ pathname: "/admin" });
              }}
              backgroundColor={
                primeiroNomeDoCaminho === "admin" ? "#D24245" : "transparent"
              }
              color={primeiroNomeDoCaminho === "admin" ? "#ffffff" : "#000000"}
              icon={GroupIcon}
              title="Usuários"
            />

            <SideItem
              funcao={() => {
                localStorage.setItem(
                  "theme",
                  theme === "dark" ? "light" : "dark"
                );
                window.location.reload();
              }}
              backgroundColor={
                primeiroNomeDoCaminho === "tema" ? "#D24245" : "transparent"
              }
              color={primeiroNomeDoCaminho === "tema" ? "#ffffff" : "#000000"}
              icon={theme === "dark" ? themeLightIcon : themeDarkIcon}
              title="Tema"
            />

            <SideItem
              funcao={() => {
                logout();
              }}
              backgroundColor={
                primeiroNomeDoCaminho === "sair" ? "#D24245" : "transparent"
              }
              color={primeiroNomeDoCaminho === "sair" ? "#ffffff" : "#000000"}
              icon={PowerSettingsNewIcon}
              title="Sair"
            />

            <Accordion
              elevation={0}
              disableGutters
              sx={{
                border: "none",
                boxShadow: "none",
              }}
              onChange={() => {
                setCollapseW(!collapseW);
                sessionStorage.setItem("collapseW", !collapseW);
              }}
              expanded={collapseW}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Atendimento</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>

                <ItemMenuSide
                  funcao={() => {
                    navigate({
                      pathname: "/app",
                    });
                  }}
                  nome={"Aplicativo"}
                  Icon={AppShortcutIcon}
                  caminho={primeiroNomeDoCaminho}
                  rota={"app"}
                />
                
                <ItemMenuSide
                  funcao={() => {
                    navigate({
                      pathname: "/metricas",
                    });
                  }}
                  nome={"Métricas"}
                  Icon={BarChartIcon}
                  caminho={primeiroNomeDoCaminho}
                  rota={"metricas"}
                />

                <ItemMenuSide
                  funcao={() => {
                    navigate({
                      pathname: "/chat",
                    });
                  }}
                  nome={"Mensagens"}
                  Icon={ForumIcon}
                  caminho={primeiroNomeDoCaminho}
                  rota={"chat"}
                />

                {/* <ItemMenuSide
                funcao={() => {
                  navigate({
                    pathname: "/disparos",
                  });
                }}
                nome={"Disparos"}
                Icon={RateReviewIcon}
                caminho={primeiroNomeDoCaminho}
                rota={"disparos"}
              /> */}

                <ItemMenuSide
                  funcao={() => {
                    navigate({
                      pathname: "/atendimentos",
                    });
                  }}
                  nome={"Atendimentos"}
                  Icon={EmojiPeopleOutlinedIcon}
                  caminho={primeiroNomeDoCaminho}
                  rota={"atendimentos"}
                />
                <ItemMenuSide
                  funcao={() => {
                    navigate({
                      pathname: "/avancado_w",
                    });
                  }}
                  nome={"Avançado"}
                  Icon={SettingsSuggestIcon}
                  caminho={primeiroNomeDoCaminho}
                  rota={"avancado_w"}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};
