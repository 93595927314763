import React from "react";
import { Select, MenuItem } from "@mui/material";

export default function SelectCustom({
  value,
  setValue,
  itens,
  valueItem,
  nameItem,
}) {
  return (
    <Select
      value={value}
      style={{
        borderRadius: "0.5rem",
        height: "39px",
        marginLeft: "3px",
        marginRight: "3px",
        width: "100%",
      }}
      MenuProps={{
        PaperProps: {
          style: {
            borderRadius: "0.5rem",
          },
        },
      }}
      onChange={setValue}
    >
      {itens.map((item, index) => (
        <MenuItem key={index} value={item[valueItem]}>
          {item[nameItem]}
        </MenuItem>
      ))}
    </Select>
  );
}
