import React from "react";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const CustomPagination = ({ onPageChange }) => {
  const proximaPagina = () => {
    onPageChange((pagina) => pagina + 1);
  };

  const paginaAnterior = () => {
    onPageChange((pagina) => pagina - 1);
  };

  return (
    <div>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => {
            paginaAnterior();
          }}
        >
          <ArrowBackIosIcon style={{ fontSize: "14px" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            proximaPagina();
          }}
        >
          <ArrowForwardIosIcon style={{ fontSize: "14px" }} />
        </IconButton>
      </div>
    </div>
  );
};

export default CustomPagination;
