import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { TableDados } from './components/TableDados';
import { Button } from "@mui/material";
import InputCustom from '../../components/InputCustom';

export default function Administradores() {

    const navigate = useNavigate();
    const [filtro, setFiltro] = useState('');

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10' >
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Administradores
                                </div>
                                <div className='col-sm-9 text-end'>
                                    <Button
                                        variant={'contained'}
                                        color={'error'}
                                        onClick={() => {
                                            navigate({
                                                pathname: "/admin/adicionar",
                                            });
                                        }}
                                        style={{
                                            width: 150,
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        Adicionar
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                            <div className='col-sm-3'>
                                    <h6>Nome do item</h6>
                                   <InputCustom
                                     value={filtro}
                                     setValue={(event) => {
                                         event.preventDefault();
                                         setFiltro(event.target.value)
                                     }}
                                     type={'text'}
                                     placeHolder={'Nome do item'}
                                    
                                    />
                                </div>
                            </div>
                            <br />
                            <TableDados nome={filtro} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
