import axios from "axios";

let apiUrl = process.env.REACT_APP_API_BASE_URL;
if(window.location.hostname === 'localhost'){
    apiUrl = process.env.REACT_APP_API_BASE_URL_DESENV;
}else if(window.location.hostname === 'atendimento-marmita-express.gerenciadores.com.br'){
    apiUrl = process.env.REACT_APP_API_BASE_URL_DESENV;
}
export const urlSessao = apiUrl + 'administrador/sessao/sessao';
export const urlLogin = apiUrl + 'administrador/login/logar';
export const urlCodigoVerificacao = apiUrl + 'administrador/codigo_verificacao/verificar';
export const tokenUsuario = getCookie("token");
//Consutas
export const urlConsultaClientes = apiUrl + 'administrador/consultas/clientes';
export const urlConsultaBuscaClientes = apiUrl + 'administrador/consultas/buscar_clientes';
export const urlConsultaEmpresas = apiUrl + 'administrador/consultas/empresas';
export const urlConsultaCategorias = apiUrl + 'administrador/consultas/categorias';
export const urlConsultaBuscaProdutos = apiUrl + 'administrador/consultas/buscar_produtos';
export const urlConsultaIngredientes = apiUrl + 'administrador/consultas/ingredientes';
export const urlConsultaFormasPagamento = apiUrl + 'administrador/consultas/formas_pagamento';

//Relatórios
export const urlRelatorioPedidosPorCliente = apiUrl + 'administrador/relatorios_pedidos_clientes/dados';
export const urlRelatorioPedidosPorEmpresa = apiUrl + 'administrador/relatorios_pedidos_empresas/dados';
export const urlRelatorioPedidos = apiUrl + 'administrador/relatorios_pedidos/dados';
export const urlRelatorioPedidosPorFormaPagamento = apiUrl + 'administrador/relatorios_pedidos_forma_pagamento/dados';
export const urlRelatorioMarmitasPorEmpresa = apiUrl + 'administrador/relatorio_marmitas_por_empresa/dados';

//Produtos
export const urlListarProdutos = apiUrl + 'administrador/produtos/listar';
export const urlAdicionarProdutos = apiUrl + 'administrador/produtos/adicionar';
export const urlInformacoesProdutos = apiUrl + 'administrador/produtos/get_id';
export const urlAlterarProdutos = apiUrl + 'administrador/produtos/editar';
export const urlApagarProdutos = apiUrl + 'administrador/produtos/apagar';
export const urlIngredientesProdutos = apiUrl + 'administrador/produtos/ingredientes';
export const urlAdicionarIngredienteProduto = apiUrl + 'administrador/produtos/adicionar_ingrediente';
export const urlRemoverIngredienteProduto = apiUrl + 'administrador/produtos/remover_ingrediente';

//Categorias
export const urlListarCategorias = apiUrl + 'administrador/categorias/listar';
export const urlAdicionarCategorias = apiUrl + 'administrador/categorias/adicionar';
export const urlInformacoesCategorias = apiUrl + 'administrador/categorias/get_id';
export const urlAlterarCategorias = apiUrl + 'administrador/categorias/editar';
export const urlApagarCategorias = apiUrl + 'administrador/categorias/apagar';

//Ingredientes
export const urlListarIngredientes = apiUrl + 'administrador/ingredientes/listar';
export const urlAdicionarIngredientes = apiUrl + 'administrador/ingredientes/adicionar';
export const urlInformacoesIngredientes = apiUrl + 'administrador/ingredientes/get_id';
export const urlAlterarIngredientes = apiUrl + 'administrador/ingredientes/editar';
export const urlApagarIngredientes = apiUrl + 'administrador/ingredientes/apagar';

//Endereços não atendidos
export const urlListarEnderecosNatendidos = apiUrl + 'administrador/enderecos_nao_atendidos/listar';
export const urlAdicionarEnderecosNatendidos = apiUrl + 'administrador/enderecos_nao_atendidos/adicionar';
export const urlInformacoesEnderecosNatendidos = apiUrl + 'administrador/enderecos_nao_atendidos/get_id';
export const urlAlterarEnderecosNatendidos = apiUrl + 'administrador/enderecos_nao_atendidos/editar';
export const urlApagarEnderecosNatendidos = apiUrl + 'administrador/enderecos_nao_atendidos/apagar';

//Formas de pagamento
export const urlListarFormasDePagamento = apiUrl + 'administrador/forma_pagamentos/listar';
export const urlAdicionarFormasDePagamento = apiUrl + 'administrador/forma_pagamentos/adicionar';
export const urlInformacoesFormasDePagamento = apiUrl + 'administrador/forma_pagamentos/get_id';
export const urlAlterarFormasDePagamento = apiUrl + 'administrador/forma_pagamentos/editar';
export const urlApagarFormasDePagamento = apiUrl + 'administrador/forma_pagamentos/apagar';

//Empresas
export const urlListarEmpresas = apiUrl + 'administrador/empresas/listar';
export const urlAdicionarEmpresas = apiUrl + 'administrador/empresas/adicionar';
export const urlInformacoesEmpresas = apiUrl + 'administrador/empresas/get_id';
export const urlAlterarEmpresas = apiUrl + 'administrador/empresas/editar';
export const urlApagarEmpresas = apiUrl + 'administrador/empresas/apagar';

//Clientes
export const urlListarClientes = apiUrl + 'administrador/clientes/listar';
export const urlAdicionarClientes = apiUrl + 'administrador/clientes/adicionar';
export const urlInformacoesClientes = apiUrl + 'administrador/clientes/get_id';
export const urlAlterarClientes = apiUrl + 'administrador/clientes/editar';
export const urlApagarClientes = apiUrl + 'administrador/clientes/apagar';
export const urlAtivaDesativaPagPrazo = apiUrl + 'administrador/clientes/pagamento_prazo';
export const urlAtivaDesativaPedirApp = apiUrl + 'administrador/clientes/pedir_aplicativo';

//Pedidos
export const urlListarPedidos = apiUrl + 'administrador/pedidos_n/listar';
export const urlAdicionarPedidos = apiUrl + 'administrador/pedidos_n/adicionar';
export const urlDadosPedidoLancamento = apiUrl + 'administrador/pedidos_n/dados_pedido_lancamento';
export const urlLimparCarrinho = apiUrl + 'administrador/pedidos_n/limpar_carrinho';
export const urlFinalizarPedido = apiUrl + 'administrador/pedidos_n/finalizar_pedido';
export const urlAlterarStatusPedidosData = apiUrl + 'administrador/pedidos_n/alterar_status_pedidos_data';
export const urlRemoverItemCarrinho = apiUrl + 'administrador/pedidos_n/remover_item';
export const urlInformacoesPedido = apiUrl + 'administrador/pedidos_n/get_id';
export const urlAlterarPedido = apiUrl + 'administrador/pedidos_n/editar';
export const urlAdicionarRemoverItens = apiUrl + 'administrador/pedidos_n/adicionar_remover_itens';
export const urlPedidoDataClienteExiste = apiUrl + 'administrador/pedidos_n/pedido_existe_data_cliente';
export const urlRecusarPedido = apiUrl + 'administrador/pedidos_n/recusar';

//Administradores
export const urlListarAdministradores = apiUrl + 'administrador/admin/listar';
export const urlAdicionarAdministradores = apiUrl + 'administrador/admin/adicionar';
export const urlInformacoesAdministradores = apiUrl + 'administrador/admin/get_id';
export const urlAlterarAdministradores = apiUrl + 'administrador/admin/editar';
export const urlApagarAdministradores = apiUrl + 'administrador/admin/apagar';

//Aplicativo
export const urlResumoApp = apiUrl + 'administrador/aplicativo/resumo';

//Gráficos
//let apiUrl = 'https://gerenciadores.com/app/marmitaexpress/';
export const urlGraficoMarmitasEmpresaContagem = apiUrl + 'administrador/graficos/marmitas_empresas';
export const urlGraficoValoresEmpresaTotal = apiUrl + 'administrador/graficos/valores_empresas';
export const urlGraficoMarmitasFormaPagamentoContagem = apiUrl + 'administrador/graficos/marmitas_forma_pagamento';
export const urlGraficoValoresFormaPagamentoTotal = apiUrl + 'administrador/graficos/valores_forma_pagamento';
export const urlGraficoItensMaisVendidosContagem = apiUrl + 'administrador/graficos/itens_mais_vendidos_contagem';
export const urlGraficoItensMaisVendidosValores = apiUrl + 'administrador/graficos/itens_mais_vendidos_valores';
export const urlGraficoMarmitasPorMeses = apiUrl + 'administrador/graficos/contagem_marmitas_por_meses';
export const urlGraficoMarmitasValoresPorMeses = apiUrl + 'administrador/graficos/valores_marmitas_por_meses';
export const urlPeriodosFiltro = apiUrl + 'administrador/graficos/periodos_filtro';

//Whatsapp

export const apiUrlArquivos = process.env.REACT_APP_API_BASE_URL_FILES;
export const urlEnvioMensagem = apiUrl + 'empresas/mensagens/adicionar';
export const urlEnvioArquivo = apiUrl + 'empresas/mensagens/enviar_arquivo';
export const urlCarregarMensagens = apiUrl + 'empresas/mensagens/listar';
export const urlCarregarChats = apiUrl + 'empresas/mensagens/chats';
export const urlMensagensAguardandoAtendimento = apiUrl + 'empresas/mensagens/aguardando_atendimento';
export const urlCountMensagensAguardandoAtendimento = apiUrl + 'empresas/mensagens/contagem_aguardando_atendimento';
export const urlIniciarAtendimento = apiUrl + 'empresas/mensagens/iniciar_atendimento';
export const urlIniciarAtendimentoManual = apiUrl + 'empresas/mensagens/iniciar_atendimento_manual';
export const urlFinalizarAtendimento = apiUrl + 'empresas/mensagens/finalizar_atendimento';
export const urlAtendentes = apiUrl + 'empresas/mensagens/atendentes';
export const urlTransferirAtendimento = apiUrl + 'empresas/mensagens/transferir_atendimento';
export const urlCountMensagens = apiUrl + 'empresas/mensagens/contagem_mensagens';
export const urlBuscarContatos = apiUrl + 'empresas/contatos/busca';

export const urlStatusSessaoW = apiUrl + 'empresas/whatsapp/status';
export const urlIniciarSessaoW = apiUrl + 'empresas/whatsapp/qr_code';
export const urlDesconectarSessaoW = apiUrl + 'empresas/whatsapp/desconectar';

export const urlInfoGeralContato = apiUrl + 'empresas/contatos/contato_info';

//Tela de contatos
export const urlListarContatos = apiUrl + 'empresas/contatos/listar';
export const urlAdicionarContato = apiUrl + 'empresas/contatos/adicionar';
export const urlInformacoesContato = apiUrl + 'empresas/contatos/get_id';
export const urlAlterarContato = apiUrl + 'empresas/contatos/editar';
export const urlApagarContatos = apiUrl + 'empresas/contatos/apagar';
export const urlAlterarContatoNumero = apiUrl + 'empresas/contatos/editar_nome';
export const urlSincronizarContatos = apiUrl + 'empresas/contatos/sincronizar';

// Tela de atendimentos

export const urlListarAtendimentos = apiUrl + 'empresas/atendimentos/listar';
export const urlInformacoesAtendimento = apiUrl + 'empresas/atendimentos/get_id';

// Dias não atendidos

export const urlListarDiasNaoAtendidos = apiUrl + 'empresas/dias_nao_atendidos/listar';
export const urlAdicionaEditaDiasNaoAtendidos = apiUrl + 'empresas/dias_nao_atendidos/adiciona_edita';
export const urlInformacoesDiasNaoAtendidos = apiUrl + 'empresas/dias_nao_atendidos/get_id';

// Recursos avançados

export const urlListarRecursosAvancado = apiUrl + 'empresas/avancado/listar';
export const urlAlterarHorariosAtendimento = apiUrl + 'empresas/avancado/alterar_horarios_atendimento';
export const urlFinalizarAtendimentoDia = apiUrl + 'empresas/avancado/finalizar_atendimentos_dia';

// Disparos

export const urlEnviarDisparos = apiUrl + 'empresas/mensagens/enviar_mensagem_massa';
export const urlListarDisparosEnviados = apiUrl + 'empresas/mensagens/listar_disparos_enviados';
export const urlListarDisparos = apiUrl + 'empresas/disparos/listar';
export const urlAdicionarDisparos = apiUrl + 'empresas/disparos/adicionar';
export const urlInformacoesDisparos = apiUrl + 'empresas/disparos/get_id';
export const urlAlterarDisparos = apiUrl + 'empresas/disparos/editar';
export const urlApagarDisparos = apiUrl + 'empresas/disparos/apagar';

//Gráficos
export const urlGraficoAtendimentoHorarios = apiUrl + 'empresas/graficos/grafico_atendimentos_horarios';
export const urlGraficoMensagensSemanaPassada = apiUrl + 'empresas/graficos/grafico_mensagens_semana_passada';
export const urlGraficoMensagensSemanaAtual = apiUrl + 'empresas/graficos/grafico_mensagens_semana_atual';
export const urlGraficoTotalContatos = apiUrl + 'empresas/graficos/grafico_total_contatos';
export const urlGraficoContatosEmpresas = apiUrl + 'empresas/graficos/grafico_contatos_empresas';


//Consultas

//Whatsapp

//Sessão do usuário

function getCookie(name) {
    let cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookiePair = cookieArray[i].split('=');
        if (name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

export const carregarSessao = async () => {
    try {
        const response = await axios.post(urlSessao, {
            token: tokenUsuario,
        });
        if (response.data === 1) {
            return true;
         } else {
             return null;
         }
    } catch(error) {
        return null;
    }
};

const verificarSessao = async () => {
    let sessao = await carregarSessao();
    if(!sessao) {
        let telaAtual = window.location.pathname.split("/")[1];
        
        if(telaAtual != 'login' && telaAtual != 'codigo_verificacao') {
            window.location.href = '/login';
        }
    }
}

verificarSessao();
