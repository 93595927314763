import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";

export default function ButtonCustom({ onClick, color, texto, icon }) {
  let IconComponent = icon;
  if (texto === "adicionar" || texto === "Adicionar") {
    IconComponent = AddIcon;
  } else if (texto === "confirmar" || texto === "Confirmar") {
    IconComponent = CheckIcon;
  }

  return (
    <Button
      variant="contained"
      color={color}
      onClick={onClick}
      className="buttonCustom"
      style={{ borderRadius: "0.5rem", fontSize: 14 }}
      endIcon={IconComponent ? <IconComponent /> : null} // Renderiza o ícone se estiver definido
    >
      {texto}
    </Button>
  );
}
