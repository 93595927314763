import React from "react";
import { Snackbar, Alert } from "@mui/material";

export const AlertCustom = ({ isOpen, setIsOpen, texto, color, tempo }) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isOpen}
        autoHideDuration={tempo}
        onClose={setIsOpen}
      >
        <Alert onClose={setIsOpen} severity={color} sx={{ width: "100%" }}>
          {texto}
        </Alert>
      </Snackbar>
    </>
  );
};