import React from "react";
import { Button } from "@mui/material";

function SideItem({ backgroundColor, color, icon: Icon, title, funcao }) {
  const theme = localStorage.getItem("theme");
  if (theme === "dark") {
    color = "#ffffff";
  }
  return (
    <div
      style={{
        margin: "4px 12px",
      }}
    >
      <Button
        onClick={() => {
          funcao();
        }}
        style={{
          margin: 0,
          fontWeight: 400,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          transition: "transform 750ms",
          willChange: "transform",
          textTransform: "capitalize",
          height: "40px",
          borderRadius: "0.5rem",
          width: "100%",
          fontSize: "14px",
          backgroundColor: backgroundColor,
          color: color,
        }}
        sx={{ maxWidth: 345 }}
      >
        &nbsp;
        {Icon && <Icon style={{ fontSize: "16px" }} />} &nbsp;&nbsp;&nbsp;{" "}
        {title}
      </Button>
    </div>
  );
}

export default SideItem;
