import React, { useEffect, useState } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { urlListarProdutos, tokenUsuario } from "../../../utils/constants";
import CustomPagination from "../../../components/PaginationTable";

export const TableDados = ({ nome }) => {
  const [dados, setDados] = useState([]);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalDados, setTotalDados] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pagina, setPagina] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams2 = new URLSearchParams(location.search);

  const listarContatos = async () => {
    try {
      const response = await axios.post(
        `${urlListarProdutos}?pagina=${pagina}`,
        {
          token: tokenUsuario,
          nome: nome,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const handlePageChange = (newPage) => {
    setPagina(newPage);
    setLoading(true);
  };

  const alterarContato = (event) => {
    navigate({
      pathname: "/produtos/alterar",
      search: createSearchParams({
        id: event.row.id,
      }).toString(),
    });
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarContatos();
      searchParams2.set("pagina", pagina);
      const novaURL = `${location.pathname}?${searchParams2.toString()}`;
      navigate(novaURL);
      setDados(lista.dados);
      setDados(lista.dados);
      setTotalPaginas(lista.total_paginas);
      setTotalDados(lista.contagem);
      setLoading(false);
    };
    run();
  }, [pagina]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarContatos();
      setDados(lista.dados);
      setLoading(false);
    };
    run();
  }, [nome]);

  const cellTipo = (tipo) => {
    let tipoTexto = "";
    if (tipo == "1") {
      tipoTexto = "Marmita";
    } else {
      tipoTexto = "Adicional";
    }
    return <span>{tipoTexto}</span>;
  };

  const cellValor = (valor) => {
    return <span>R$ {valor}</span>;
  };

  const cellTamanho = (tamanho) => {
    let textoTamanho = 'Não informado';
    if(tamanho === 'M'){
      textoTamanho = 'Mini';
    } else if(tamanho === 'G'){
      textoTamanho = 'Grande';
    }else if(tamanho === 'E'){
      textoTamanho = 'Executiva';
    }else {
      textoTamanho = 'Não informado';
    }
    return <span>{textoTamanho}</span>;
  };

  const columns = [
    { field: "nome", headerName: "Nome", width: 355 },
    {
      field: "valor",
      headerName: "Valor",
      renderCell: (params) => cellValor(params.value),
      width: 300,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      renderCell: (params) => cellTipo(params.value),
      width: 300,
    },
    {
      field: "tamanho",
      headerName: "Tamanho",
      renderCell: (params) => cellTamanho(params.value),
      width: 200,
    },
  ];

  return (
    <>
      <div style={{ width: "100%" }}>
        {loading ? (
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        ) : (
          <DataGrid
            rows={dados}
            columns={columns}
            loading={loading}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            disableRowSelectionOnClick
            rowHeight={40}
            headerHeight={40}
            autoHeight={true}
            onRowClick={alterarContato}
            components={{
              Pagination: (props) => (
                <CustomPagination {...props} paginaDados={pagina} totalPaginas={totalPaginas} totalDados={totalDados} onPageChange={handlePageChange} />
              ),
            }}
          />
        )}
      </div>
    </>
  );
};
