import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { urlLogin } from '../../utils/constants';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { AlertCustom } from "../../components/AlertCustom";
import LoginIcon from '@mui/icons-material/Login';
import InputCustom from '../../components/InputCustom';
import ButtonCustom from '../../components/ButtonCustom';

export default function Login() {

    const navigate = useNavigate();
    const [usuario, setUsuario] = useState([]);
    const [senha, setSenha] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [erroAlert, setErrorAlert] = useState(false);

    const alterarUsuario = (event) => {
        event.preventDefault();
        setUsuario(event.target.value);
    }

    const alteraSenha = (event) => {
        event.preventDefault();
        setSenha(event.target.value);
    }

    const fecharSucessAlert = () => {
        setSuccessAlert(false);
    };

    const fechaErrorAlert = () => {
        setErrorAlert(false);
    };

    const fazerLogin = async () => {
        setLoading(true);
        try {
            const response = await axios
                .post(urlLogin, {
                    usuario: usuario,
                    senha: senha
                });
            if (response.data.status == '1') {
                navigate({
                    pathname: '/codigo_verificacao'
                })
            } else {
                setErrorAlert(true);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <AlertCustom
                texto={"Login efetuado com sucesso, aguarde..."}
                color="success"
                isOpen={successAlert}
                setIsOpen={fecharSucessAlert}
                tempo={2000}
            />
            <AlertCustom
                texto={"Dados inválidos"}
                color="error"
                isOpen={erroAlert}
                setIsOpen={fechaErrorAlert}
                tempo={3000}
            />
            <div>
                <div className='row'>
                    <div className='col-sm-4'></div>
                    <div className='col-sm-4'>
                        <div className='card' style={{ borderRadius: '0.5rem', marginTop: '20vh', padding: '50px' }}>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <h2>Bem vindo(a) de volta!</h2>
                            </div>

                            <br />
                            <InputCustom
                                value={usuario}
                                setValue={alterarUsuario}
                                type={'text'}
                                placeHolder={'Usuario'}
                            />
                            <br />
                            <InputCustom
                                value={senha}
                                setValue={alteraSenha}
                                type={'password'}
                                placeHolder={'Senha'}
                            />

                            <br /><br />

                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '20%' }}>
                                    <CircularProgress color="primary" />
                                </div>
                            ) : (
                                <ButtonCustom
                                    onClick={() => {
                                        fazerLogin();
                                    }}
                                    color={"error"}
                                    texto={"Entrar"}
                                    icon={LoginIcon}
                                />

                            )}

                        </div>
                    </div>
                    <div className='col-sm-4'></div>
                </div>
            </div>
        </>
    );
}
