import React from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function AutoCompleteCustom({
  itens,
  value,
  onChange,
  nameItem,
}) {
  return (
    <>
      <Autocomplete
        disablePortal
        options={itens}
        getOptionLabel={(option) => `${option[nameItem]}`}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "& .MuiInputBase-input": {},
              "& .MuiInputBase-root": {
                height: "39px",
                borderRadius: "0.5rem",
              },
              "& .MuiOutlinedInput-root": {
                height: "39px",
                padding: "10px",
              },
              width: "100%",
            }}
          />
        )}
      />
    </>
  );
}
