import React, { useState } from 'react';
import axios from "axios";
import { MenuSide } from '../../components/MenuSide';
import { Button, LinearProgress } from "@mui/material";
import { tokenUsuario, urlEnviarDisparos } from "../../utils/constants";
import { TableDisparosEnviados } from './components/TableDisparosEnviados';

export default function EnviarMensagensMassa() {

    const [atualizar, setAtualizar] = useState(0);
    const [textoEnviar, setTextoEnviar] = useState('Disparar mensagens');
    const [empresa, setEmpresa] = useState(null);
    const [loadingEnvio, setLoadingEnvio] = useState(false);
    const url = window.location.href;
    const urlBase = new URL(url);

    const enviarMensagens = async () => {
        setLoadingEnvio(true);
        setTextoEnviar('Enviando mensagens');
        try {
            const response = await axios.post(urlEnviarDisparos, {
                token: tokenUsuario,
                id: urlBase.searchParams.get('id'),
            });
            if (response.data.status === 'enviado') {
                enviarMensagens();
                setAtualizar(Math.random);
            } else if (response.data.status === 'nao_enviado') {
                setAtualizar(Math.random);
            } else {
                setAtualizar(Math.random);
                setLoadingEnvio(false);
                setTextoEnviar('Sem mensagens para enviar');
            }
        } catch (error) {
            return null;
        }
    };

    const buscarEnvios = (valor) => {
        if (valor === 0) {
            setTextoEnviar('Sem mensagens para enviar');
        }
    }

    const buscarEmpresa = (value) => {
        setEmpresa(value);
    }

    return (
        <><div className='row'>

            <div className='col-sm-2'>
                <MenuSide />
            </div>
            <div className='col-sm-10'>
                <div className='card'
                    style={{ margin: '30px', }}>
                    <div className='card-header'>
                        <div className='row'>
                            <div className='col-sm-6' style={{ fontWeight: 700, fontSize: '25px', }}>
                                {textoEnviar} - {empresa}
                            </div>
                        </div>
                    </div>

                    <div className='card-footer'>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <br />
                                {loadingEnvio ? (
                                    <LinearProgress />
                                ) : (
                                    <span></span>
                                )}
                            </div>
                            <div className='col-sm-4 text-end'>
                                {textoEnviar === 'Sem mensagens para enviar' ? (
                                    <span></span>
                                ) : (
                                    <Button
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={() => {
                                            enviarMensagens();
                                        }}
                                        style={{
                                            width: 250,
                                            height: 40,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        {textoEnviar}
                                    </Button>
                                )}

                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <TableDisparosEnviados valorAtualiza={atualizar} totalEnviar={buscarEnvios} empresa={buscarEmpresa} />
            </div>
        </div>
        </>
    );
}
