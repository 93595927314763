import React, { useEffect, useState } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import { urlListarAtendimentos, tokenUsuario } from "../../../utils/constants";
import CustomPagination from "../../../components/PaginationTable";

export function converterDataFormato(dataOriginal) {
  if (dataOriginal) {
    //@ts-ignore
    const partes = dataOriginal.split(" ");
    const dataPartes = partes[0].split("-");
    const horaPartes = partes[1].split(":");

    const dia = dataPartes[2];
    const mes = dataPartes[1];
    const ano = dataPartes[0];

    const hora = horaPartes[0];
    const minutos = horaPartes[1];

    const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minutos}`;

    return dataFormatada;
  }
}

export const TableAtendimentos = ({ nome, atendente, status, inicio, fim }) => {
  const [contatos, setAtendimentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagina, setPagina] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams2 = new URLSearchParams(location.search);

  const listarAtendimentos = async () => {
    try {
      const response = await axios.post(
        `${urlListarAtendimentos}?pagina=${pagina}`,
        {
          token: tokenUsuario,
          nome: nome,
          atendente: atendente,
          status: status,
          inicio: inicio,
          fim: fim,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const alterarContato = (event) => {
    navigate({
      pathname: "/atendimentos/informacoes",
      search: createSearchParams({
        id: event.row.id,
      }).toString(),
    });
  };

  const handlePageChange = (newPage) => {
    setPagina(newPage);
    setLoading(true);
    listarAtendimentos(newPage);
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarAtendimentos();
      setAtendimentos(lista);
      searchParams2.set("pagina", pagina);
      const novaURL = `${location.pathname}?${searchParams2.toString()}`;
      navigate(novaURL);
      setLoading(false);
    };
    run();
  }, [pagina]);

  const cellData = (data) => {
    return <span>{converterDataFormato(data)}</span>;
  };

  const cellStatus = (status) => {
    let statusTexto = "";
    if (status == "1") {
      statusTexto = "Aguardando atendimento";
    } else if (status == "2") {
      statusTexto = "Em atendimento";
    } else {
      statusTexto = "Atendimento finalizado";
    }
    return <span>{statusTexto}</span>;
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarAtendimentos();
      setAtendimentos(lista);
      setLoading(false);
    };
    run();
  }, [nome, atendente, status, inicio, fim]);

  const columns = [
    { field: "nome", headerName: "Nome", width: 220 },
    { field: "numero", headerName: "Número", width: 220 },
    { field: "atendente", headerName: "Atendente", width: 200 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => cellStatus(params.value),
      width: 300,
    },
    {
      field: "data",
      headerName: "Data",
      renderCell: (params) => cellData(params.value),
      width: 250,
    },
  ];

  return (
    <>
      <div style={{ width: "100%" }}>
        {loading ? (
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        ) : (
          <DataGrid
            rows={contatos}
            columns={columns}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            disableRowSelectionOnClick
            rowHeight={40}
            headerHeight={40}
            autoHeight={true}
            onRowClick={alterarContato}
            getRowId={(row) => row.id}
            components={{
              Pagination: (props) => (
                <CustomPagination {...props} onPageChange={handlePageChange} />
              ),
            }}
            sx={{
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
          />
        )}
      </div>
    </>
  );
};
