import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { Button, TextareaAutosize } from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { urlAlterarDisparos, urlInformacoesDisparos, urlApagarDisparos, tokenUsuario } from "../../utils/constants";

export default function AlterarContato() {

    const navigate = useNavigate();
    const [mensagem, setMensagem] = useState('');

    const url = window.location.href;
    const urlBase = new URL(url);

    const informacoesContato = async () => {
        try {
            const response = await axios.post(urlInformacoesDisparos, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    const alterarContato = async () => {
        try {
            const response = await axios.post(urlAlterarDisparos, {
                mensagem: mensagem,
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            if (response) {
                navigate({
                    pathname: "/disparos",
                });
            }
        } catch (error) {
            return null;
        }
    };

    const excluirContato = async () => {
        try {
            const response = await axios.post(urlApagarDisparos, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            if (response) {
                navigate({
                    pathname: "/disparos",
                });
            }
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {
        const run = async () => {
            const info = await informacoesContato();
            setMensagem(info.mensagem);
        }
        run();
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br /> <div className='card'
                        style={{ marginRight: '1%', }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Alterar disparo
                                </div>
                                <div className='col-sm-9 text-end'>
                                    <Button
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={() => {
                                            excluirContato();
                                        }}
                                        style={{
                                            width: 50,
                                            height: 40,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        <DeleteForeverOutlinedIcon style={{ color: '#ffffff' }} />
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <h6>Mensagem</h6>
                                    <TextareaAutosize
                                        value={mensagem}
                                        onChange={(event) => {
                                            event.preventDefault();
                                            setMensagem(event.target.value);
                                        }}
                                        style={{
                                            width: '100%',
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                        minRows={12}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                                <div className='col-sm-9 text-end'>
                                    <Button
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={() => {
                                            alterarContato();
                                        }}
                                        style={{


                                            width: 150,
                                            height: 40,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        Confirmar
                                    </Button>

                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
