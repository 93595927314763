import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { TableContatos } from './components/TableContatos';
import { Button, Input, Select, FormControl, MenuItem } from "@mui/material";

export default function Disparos() {

    const navigate = useNavigate();
    const [filtro, setFiltro] = useState('');
    const [status, setStatus] = useState(0);
    
    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br /> <div className='card'
                        style={{ marginRight: '1%', }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Disparos
                                </div>
                                <div className='col-sm-9 text-end'>
                                    <Button
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={() => {
                                            navigate({
                                                pathname: "/disparos/adicionar",
                                            });
                                        }}
                                        style={{
                                            width: 150,
                                            height: 40,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        Adicionar
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <h6>Nome do item</h6>
                                    <Input
                                        placeholder='Nome do item'
                                        onChange={(event) => {
                                            event.preventDefault();
                                            setFiltro(event.target.value)
                                        }}
                                        value={filtro}
                                        type="tel"
                                        style={{
                                            height: "45px",
                                            borderRadius: "0.5rem",
                                            width: '100%',
                                            padding: '10px'
                                        }} />
                                </div>
                                <div className='col-sm-3'>
                                    <h6>&nbsp;Status</h6>
                                    <FormControl fullWidth>
                                        <Select
                                            value={status}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setStatus(event.target.value);
                                            }}
                                            style={{
                                                borderRadius: "0.5rem",
                                                height: "45px",
                                                marginLeft: "3px",
                                                marginRight: "3px",
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        borderRadius: "0.5rem",
                                                    },
                                                },
                                            }}
                                        >

                                            <MenuItem
                                                value={1}
                                            >
                                                Enviado
                                            </MenuItem>
                                            <MenuItem

                                                value={0}
                                            >
                                                Não enviado
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-sm-6'></div>

                            </div>
                            <br />
                            <TableContatos nome={filtro} status={status} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
