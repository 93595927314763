import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  urlGraficoItensMaisVendidosValores,
  tokenUsuario,
} from "../../utils/constants";
import Chart from "chart.js/auto";
import { CircularProgress } from "@mui/material";

export default function GraficoItensMaisVendidosValores({ ano }) {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [loading, setLoading] = useState(false);

  const generateRandomColor = () => {
    return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
      Math.random() * 255
    )}, ${Math.floor(Math.random() * 255)}, 0.6)`;
  };

  const updateChart = (labels, data, backgroundColors) => {
    if (chart) {
      chart.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    const newChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Total em R$",
            data: data,
            backgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    setChart(newChart);
  };

  const listarItens = async () => {
    try {
      const response = await axios.post(urlGraficoItensMaisVendidosValores, {
        data: ano,
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const carregarItens = async () => {
    const itens = await listarItens();
    const labels = itens.map((item) => item.nome_produto);
    const data = itens.map((item) => item.total_vendido);
    const backgroundColors = data.map(() => generateRandomColor());

    updateChart(labels, data, backgroundColors);
  }

  useEffect(() => {
    carregarItens();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [ano]);

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        </div>
      ) : (
        <canvas ref={chartRef} style={{ maxHeight: "400px" }}></canvas>
      )}
    </>
  );
}
