import React, { useEffect, useState } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { urlListarIngredientes, tokenUsuario } from "../../../utils/constants";
import CustomPagination from "../../../components/PaginationTable";



export const TableDados = ({ nome }) => {
  const [contatos, setContatos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagina, setPagina] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams2 = new URLSearchParams(location.search);

  const listarContatos = async () => {
    try {
      const response = await axios.post(
        `${urlListarIngredientes}?pagina=${pagina}`,
        {
          token: tokenUsuario,
          nome: nome,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const handlePageChange = (newPage) => {
    setPagina(newPage);
    setLoading(true);
    listarContatos(newPage)
      .then((lista) => {
        const contatosFiltrados = lista.filter((item) =>
          item.nome.toLowerCase().includes(nome.toLowerCase())
        );
        setContatos(contatosFiltrados);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const alterarContato = (event) => {
    navigate({
      pathname: "/ingredientes/alterar",
      search: createSearchParams({
        id: event.row.id,
      }).toString(),
    });
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarContatos(pagina);
      searchParams2.set("pagina", pagina);
      const novaURL = `${location.pathname}?${searchParams2.toString()}`;
      navigate(novaURL);
      setContatos(lista);
      setLoading(false);
    };
    run();
  }, [pagina]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarContatos();
      setContatos(lista);
      setLoading(false);
    };
    run();
  }, [nome]);

  const cellStatus = (status) => {
    let tipoTexto = "";
    if (status == "1") {
      tipoTexto = "Disponível";
    } else {
      tipoTexto = "Indisponível";
    }
    return <span>{tipoTexto}</span>;
  };

  const cellValor = (valor) => {
    return <span>R$ {valor}</span>;
  };

  const columns = [
    { field: "nome", headerName: "Nome", width: 255 },
     {
      field: "valor",
      headerName: "Valor",
      renderCell: (params) => cellValor(params.value),
      width: 200,
    },
    {
      field: "status",
      headerName: "Tipo",
      renderCell: (params) => cellStatus(params.value),
      width: 200,
    },
    { field: "nome_categoria", headerName: "Categoria", width: 255 },
  ];

  return (
    <>
      <div style={{ width: "100%" }}>
        {loading ? (
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        ) : (
          <DataGrid
            rows={contatos}
            columns={columns}
            loading={loading}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            disableRowSelectionOnClick
            rowHeight={40}
            headerHeight={40}
            autoHeight={true}
            onRowClick={alterarContato}
            components={{
              Pagination: (props) => (
                <CustomPagination {...props} onPageChange={handlePageChange} />
              ),
            }}
          
          />
        )}
      </div>
    </>
  );
};
