import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { urlFinalizarAtendimento, tokenUsuario } from "../../../utils/constants";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from "@mui/material";
import { AlertCustom } from "../../../components/AlertCustom";
import ButtonCustom from "../../../components/ButtonCustom";

export const ModalDialogFinalizarAtendimento = ({
  isOpen,
  setIsOpen,
  fecharAtendimento,
  nome,
  id,
  numero,
}) => {
  let navigate = useNavigate();
  const [successAlert, setSuccessAlert] = useState(false);
  const [erroAlert, setErrorAlert] = useState(false);

  const fecharSucessAlert = () => {
    setSuccessAlert(false);
  }

  const fechaErrorAlert = () => {
    setErrorAlert(false);
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  const finalizarAtendimento = () => {
    axios
    .post(urlFinalizarAtendimento, {
      id: id,
      numero: numero,
      token: tokenUsuario
    })
    .then((response) => {
      if(response.data.status == 'finalizado') {
        setSuccessAlert(true);
        setIsOpen(false);
        fecharAtendimento();
        navigate({
          pathname: "/chat",
        });   
      } else {
          setErrorAlert(true);
      }
    })
    .catch((error) => {
      console.log(error);
    });
   
  };

  return (
    <>

      <AlertCustom texto={'Atendimento finalizado com sucesso'} color="success" isOpen={successAlert} setIsOpen={fecharSucessAlert} tempo={2000} />
      <AlertCustom texto={'Não foi possível finalizar esse atendimento.'} color="error" isOpen={erroAlert} setIsOpen={fechaErrorAlert} tempo={3000} />
       
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Finalizar atendimento"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja finalizar o atendimento? Cliente: {nome}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <ButtonCustom color={'error'} texto={'Cancelar'} variant="contained" onClick={handleClose} />
          <ButtonCustom color={'success'} texto={'Confirmar'} variant="contained" onClick={finalizarAtendimento} />
        </DialogActions>
      </Dialog>
    </>
  );
};
