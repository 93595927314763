import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { Button, MenuList, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { urlAlterarProdutos, urlInformacoesProdutos, urlConsultaCategorias, urlAdicionarIngredienteProduto, urlRemoverIngredienteProduto, urlIngredientesProdutos, urlApagarProdutos, tokenUsuario } from "../../utils/constants";
import ButtonCustom from '../../components/ButtonCustom';
import InputCustom from '../../components/InputCustom';
import AutoCompleteCustom from '../../components/AutoCompleteCustom';
import SelectCustom from "../../components/SelectCustom";
import DeleteIcon from '@mui/icons-material/Delete';

export default function AlterarProduto() {

    const navigate = useNavigate();
    const [nome, setNome] = useState('');
    const [valor, setValor] = useState('');
    const [carneLimite, setCarneLimite] = useState('');
    const [tipo, setTipo] = useState('');
    const [ingredientes, setIngredientes] = useState([]);
    const [ingredientesProduto, setIngredientesProduto] = useState([]);
    const [ingrediente, setIngrediente] = useState(null);
    const [limiteIngrediente, setLimiteIngrediente] = useState(1);
    const [qntIngrediente, setQntIngrediente] = useState(1);
    const [categoria, setCategoria] = useState('');
    const [tamanho, setTamanho] = useState('');
    const [categorias, setCategorias] = useState([]);
    const [imagem, setImagem] = useState('');

    const url = window.location.href;
    const urlBase = new URL(url);
    const idProduto = urlBase.searchParams.get('id');

    const informacoesContato = async () => {
        try {
            const response = await axios.post(urlInformacoesProdutos, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    const consultaIngredientes = async () => {
        try {
            const response = await axios.post(urlIngredientesProdutos, {
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            console.log(error)
        }
    };

    const adicionarIngrediente = async () => {
        try {
            const response = await axios.post(urlAdicionarIngredienteProduto, {
                produtoId: idProduto,
                idIngrediente: ingrediente.id,
                limite: limiteIngrediente,
                qnt: qntIngrediente,
                token: tokenUsuario,
            });
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    };

    const removerIngrediente = async (id) => {
        try {
            const response = await axios.post(urlRemoverIngredienteProduto, {
                id: id,
                token: tokenUsuario,
            });
            console.log(response)
        } catch (error) {
            return null;
        }
    };

    const alterarInformacoes = async () => {
        const form = new FormData();
        form.append("imagem", imagem);
        form.append("nome", nome);
        form.append("valor", valor);
        form.append("carne_limite", carneLimite);
        form.append("tipo", tipo);
        form.append("categoria", categoria);
        form.append("tamanho", tamanho);
        form.append("token", tokenUsuario);
        form.append("id", urlBase.searchParams.get('id'));

        try {
            const response = await axios.post(urlAlterarProdutos, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (response.data == 1) {
                navigate({
                    pathname: "/produtos",
                });
            }
        } catch (error) {
            return null;
        }
    };

    const altererarArquivo = (event) => {
        event.preventDefault();
        setImagem(event.target.files[0]);
    };

    const excluirInformacoes = async () => {
        try {
            const response = await axios.post(urlApagarProdutos, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            if (response.data == 1) {
                navigate({
                    pathname: "/produtos",
                });
            }
        } catch (error) {
            return null;
        }
    }

    const carregarInformacoes = async () => {
        const response = await informacoesContato();
        console.log(response.ingredientes)
        const info = response.dados;
        setNome(info.nome);
        setValor(info.valor);
        setTipo(info.tipo);
        setCarneLimite(info.carne_limite);
        setCategoria(info.categoria);
        setTamanho(info.tamanho);
        setIngredientesProduto(response.ingredientes);

        const ingredientesC = await consultaIngredientes();
        setIngredientes(ingredientesC);
    }
    useEffect(() => {
        carregarInformacoes();
    }, []);

    const dataArray = [{
        nome: 'Marmita',
        id: 1
    },
    {
        nome: 'Adicional',
        id: 2
    }];

    const consultarCategorias = async () => {
        try {
            const response = await axios.post(urlConsultaCategorias, {
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        const run = async () => {
            let categoriasData = await consultarCategorias();
            setCategorias(categoriasData);
        }
        run();
    }, []);

    const tamanhosProdutos = [
        {
            id: 'M',
            nome: 'Mini'
        },
        {
            id: 'G',
            nome: 'Grande'
        },
        {
            id: 'E',
            nome: 'Executiva'
        },
        {
            id: 'N',
            nome: 'Não informado'
        }
    ];

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Alterar produto
                                </div>
                                <div className='col-sm-9 text-end'>
                                    <Button
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={() => {
                                            excluirInformacoes();
                                        }}
                                        style={{
                                            color: '#111B21',
                                            width: 50,
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        <DeleteForeverOutlinedIcon style={{ color: '#ffffff' }} />
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <h6>Nome</h6>
                                    <InputCustom
                                        value={nome}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setNome(event.target.value)
                                        }}
                                        type={'text'}
                                        placeHolder={'Nome'}

                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Valor</h6>
                                    <InputCustom
                                        value={valor}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setValor(event.target.value)
                                        }}
                                        type={'text'}
                                        placeHolder={'Valor'}

                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Limite de carnes</h6>
                                    <InputCustom
                                        value={carneLimite}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setCarneLimite(event.target.value)
                                        }}
                                        type={'number'}
                                        placeHolder={'Limite de carnes'}

                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <h6>Tipo</h6>
                                    <SelectCustom
                                        value={tipo}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setTipo(event.target.value);
                                        }}
                                        itens={dataArray}
                                        valueItem={"id"}
                                        nameItem={"nome"}
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <br />
                                    <h6>Categoria</h6>
                                    <SelectCustom
                                        value={categoria}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setCategoria(event.target.value);
                                        }}
                                        itens={categorias}
                                        valueItem={"id"}
                                        nameItem={"nome"}
                                    />
                                    <br />
                                    <h6>Imagem</h6>
                                    <input className="form-control" onChange={altererarArquivo} type="file" />
                                    {imagem}
                                </div>
                                <div className='col-sm-2'>
                                    <br />
                                    <h6>Tamanho</h6>
                                    <SelectCustom
                                        value={tamanho}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setTamanho(event.target.value);
                                        }}
                                        itens={tamanhosProdutos}
                                        valueItem={"id"}
                                        nameItem={"nome"}
                                    />
                                </div>
                                {tipo === 1 ? (
                                    <div className='col-sm-3'>
                                        <br />
                                        <h6>Ingredientes para adicionar</h6>
                                        <AutoCompleteCustom
                                            value={ingrediente}
                                            nameItem={'nome'} itens={ingredientes}
                                            onChange={(event, newValue) => {
                                                setIngrediente(newValue);
                                            }} />
                                        <br />
                                        <h6>Quantidade</h6>
                                        <InputCustom
                                            value={qntIngrediente}
                                            setValue={(event) => {
                                                event.preventDefault();
                                                setQntIngrediente(event.target.value)
                                            }}
                                            type={'number'}
                                            placeHolder={'qntIngrediente'}

                                        />
                                        <br />
                                        <h6>Limite</h6>
                                        <InputCustom
                                            value={limiteIngrediente}
                                            setValue={(event) => {
                                                event.preventDefault();
                                                setLimiteIngrediente(event.target.value)
                                            }}
                                            type={'number'}
                                            placeHolder={'setLimiteIngrediente'}

                                        />
                                        <br /><br />
                                        <ButtonCustom
                                            onClick={() => {
                                                adicionarIngrediente();
                                                setTimeout(() => {
                                                    carregarInformacoes();
                                                }, 500);
                                            }}
                                            color={"error"}
                                            texto={"Adicionar"}
                                            icon={null}
                                        />
                                    </div>
                                ) : null}
                                {tipo === 1 ? (
                                    <div className='col-sm-4'>
                                        <br />
                                        <h6>Cardápio do Produto</h6>
                                        <br />
                                        <div className='card'>
                                            <MenuList>
                                                {ingredientesProduto.map((item, index) => (
                                                    <MenuItem key={index}>
                                                        <ListItemIcon onClick={() => {
                                                            removerIngrediente(item.id);
                                                            setTimeout(() => {
                                                                carregarInformacoes();
                                                            }, 500);
                                                        }}>
                                                            <DeleteIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText>{item.nome}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </div>
                                    </div>
                                ) : null}

                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                                <div className='col-sm-7'></div>
                                <div className='col-sm-2 text-end'>
                                    <ButtonCustom
                                        onClick={() => {
                                            alterarInformacoes();
                                        }}
                                        color={"error"}
                                        texto={"Confirmar"}
                                        icon={null}
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
