import React, { useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { TextField, FormControl } from "@mui/material";
import ButtonCustom from '../../components/ButtonCustom';
import { urlAdicionarCategorias, tokenUsuario } from "../../utils/constants";

export default function AdicionarCategoria() {

    const navigate = useNavigate();
    const [nome, setNome] = useState('');

    const adicionarInformacoes = async () => {
        try {
            const response = await axios.post(urlAdicionarCategorias, {
                nome: nome,
                token: tokenUsuario,
            });
            if (response.data == 1) {
                navigate({
                    pathname: "/categorias",
                });
            }
        } catch (error) {
            return null;
        }
    };

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Adicionar categoria
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <h6>Nome</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            placeholder='Nome'
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setNome(event.target.value)
                                            }}
                                            value={nome}
                                            type="text"
                                            InputProps={{
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                                <div className='col-sm-7'></div>
                                <div className='col-sm-2 text-end'>
                                    <ButtonCustom
                                        onClick={() => {
                                            adicionarInformacoes();
                                        }}
                                        color={"error"}
                                        texto={"Confirmar"}
                                        icon={null}
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
