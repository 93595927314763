import React, { useEffect, useState } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { urlListarDisparos, tokenUsuario } from "../../../utils/constants";
import CustomPagination from "../../../components/PaginationTable";



export function converterDataFormato(dataOriginal : { dataOriginal: String }) {
  //@ts-ignore
  const partes = dataOriginal.split(" ");
  const dataPartes = partes[0].split("-");
  const horaPartes = partes[1].split(":");

  const dia = dataPartes[2];
  const mes = dataPartes[1];
  const ano = dataPartes[0];

  const hora = horaPartes[0];
  const minutos = horaPartes[1];

  const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minutos}`;

  return dataFormatada;
}

export const TableContatos = ({ nome, status }) => {
  const [contatos, setContatos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagina, setPagina] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams2 = new URLSearchParams(location.search);

  const listarContatos = async () => {
    try {
      const response = await axios.post(
        `${urlListarDisparos}?pagina=${pagina}`,
        {
          token: tokenUsuario,
          nome: nome,
          status: status,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const handlePageChange = (newPage) => {
    setPagina(newPage);
    setLoading(true);
    listarContatos(newPage)
      .then((lista) => {
        const contatosFiltrados = lista.filter((item) =>
          item.nome.toLowerCase().includes(nome.toLowerCase())
        );
        setContatos(contatosFiltrados);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const alterarContato = (event) => {
    navigate({
      pathname: "/disparos/detalhe",
      search: createSearchParams({
        id: event.row.id,
      }).toString(),
    });
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarContatos(pagina);
      searchParams2.set("pagina", pagina);
      const novaURL = `${location.pathname}?${searchParams2.toString()}`;
      navigate(novaURL);
      setContatos(lista);
      setLoading(false);
    };
    run();
  }, [pagina, nome, status]);

  const cellData = (data) => {
    return <span>{converterDataFormato(data)}</span>;
  };

  const cellStatus = (status) => {
    let textStatus = 'Não enviado';
    if(status === 1){
      textStatus = 'Enviado';
    } 
    return <span>{textStatus}</span>;
  };

  const columns = [
    { field: "id", headerName: "#", width: 155 },
    { field: "mensagem_resumida", headerName: "Mensagem", width: 355 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => cellStatus(params.value),
      width: 155,
    },
    {
      field: "data",
      headerName: "Data",
      renderCell: (params) => cellData(params.value),
      width: 255,
    },
    { field: "nome_empresa", headerName: "Empresa", width: 320 },
  ];

  return (
    <>
      <div style={{ width: "100%" }}>
        {loading ? (
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        ) : (
          <DataGrid
            rows={contatos}
            columns={columns}
            loading={loading}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            disableRowSelectionOnClick
            rowHeight={40}
            headerHeight={40}
            autoHeight={true}
            onRowClick={alterarContato}
            components={{
              Pagination: (props) => (
                <CustomPagination {...props} onPageChange={handlePageChange} />
              ),
            }}
             sx={{
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
          />
        )}
      </div>
    </>
  );
};
