import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import {
  urlListarDisparosEnviados,
  tokenUsuario,
} from "../../../utils/constants";
import CustomPagination from "./PaginationDisparosEnviados";

export function converterDataFormato(dataOriginal: { dataOriginal: String }) {
  //@ts-ignore
  const partes = dataOriginal.split(" ");
  const dataPartes = partes[0].split("-");
  const horaPartes = partes[1].split(":");

  const dia = dataPartes[2];
  const mes = dataPartes[1];
  const ano = dataPartes[0];

  const hora = horaPartes[0];
  const minutos = horaPartes[1];

  const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minutos}`;

  return dataFormatada;
}

export const TableDisparosEnviados = ({
  valorAtualiza,
  totalEnviar,
  empresa,
}) => {
  const [contatos, setDisparos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalEnviados, setTotalEnviados] = useState(null);
  const [totalNaoEnviados, setTotalNaoEnviados] = useState(null);
  
  const url = window.location.href;
  const urlBase = new URL(url);
  const id = urlBase.searchParams.get("id");

  const listarDisparos = async () => {
    try {
      const response = await axios.post(urlListarDisparosEnviados, {
        token: tokenUsuario,
        id: id,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarDisparos();
      setDisparos(lista.disparos_enviados);
      setTotalEnviados(lista.total_disparos_enviados);
      setTotalNaoEnviados(lista.total_disparos_nao_enviados);
      totalEnviar(lista.total_disparos_nao_enviados);
      empresa(lista.empresa);
      setLoading(false);
    };
    run();
  }, [valorAtualiza]);

  const columns = [
    { field: "id", headerName: "#", width: 220 },
    { field: "nome", headerName: "Nome", width: 220 },
    { field: "numero", headerName: "Número", width: 220 },
  ];

  return (
    <>
      <div
        className="card"
        style={{ margin: "30px", backgroundColor: "#1F2C33", color: "#ffffff" }}
      >
        <div className="card-header">
          <div className="row">
            <div
              className="col-sm-6"
              style={{ fontWeight: 700, fontSize: "25px" }}
            >
              Últimas mensagens enviadas
            </div>

            <div className="col-sm-1"></div>

            <div className="col-sm-2">
              Total enviados : {totalEnviados}
            </div>
            <div className="col-sm-3">Total ainda não enviados : {totalNaoEnviados}</div>
          </div>
        </div>
        <div className="card-body">
          <div style={{ width: "100%" }}>
            {loading ? (
              <CircularProgress
                style={{ height: "20px", width: "20px" }}
                color="primary"
              />
            ) : (
              <DataGrid
                rows={contatos}
                columns={columns}
                disableSelectionOnClick
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
                rowHeight={40}
                headerHeight={40}
                autoHeight={true}
                getRowId={(row) => row.id}
                components={{
                  Pagination: (props) => <CustomPagination {...props} />,
                }}
                sx={{
                  border: "none",
                  overflow: "hidden",
                  "& .MuiDataGrid-row": {
                    background: "##2A3942",
                    borderColor: "#E0E0E0 !important",
                    borderRight: 0,
                    borderLeft: 0,
                    borderTop: 1,
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#ffffff",
                  },
                  "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader:focus-within":
                    {
                      background: "#2A3942",
                      fontSize: "14px",
                      color: "#ffffff",
                      boxShadow: "inset 0px -1px 0px #E0E0E0",
                      overflow: "hidden",
                      outline: "none !important",
                      border: "none !important",
                    },
                  "& .MuiDataGrid-iconSeparator": {
                    display: "none",
                  },
                  ".MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-row:hover": {
                    cursor: "pointer",
                  },
                  ".MuiDataGrid-iconButtonContainer": {
                    visibility: "visible",
                    border: "none !important",
                    transform: "scaleY(-1)",
                  },
                  ".MuiDataGrid-sortIcon": {
                    opacity: "inherit !important",
                    color: "#EFF2F4",
                    border: "none !important",
                  },
                  ".MuiDataGrid-iconButtonContainer:focus-within": {
                    outline: "none !important",
                    border: "none !important",
                  },
                  "& .MuiDataGrid-sortIcon:focus-within": {
                    outline: "none !important",
                    border: "none !important",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
