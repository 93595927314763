import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { MenuSide } from "../../components/MenuSide";
import GraficoMarmitasEmpresas from "./GraficoMarmitasEmpresas";
import GraficoValoresEmpresas from "./GraficoValoresEmpresas";
import GraficoMarmitasFormaPagamento from "./GraficoMarmitasFormasPagamento";
import GraficoValoresFormaPagamento from "./GraficoValoresFormasPagamento";
import GraficoItensMaisVendidosContagem from "./ItensMaisVendidosContagem";
import GraficoItensMaisVendidosValores from "./ItensMaisVendidosValores";
import GraficoMarmitasMeses from "./GraficoMarmitasMeses";
import GraficoValoresMeses from "./GraficoValoresMeses";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Button } from "@mui/material";
import { urlPeriodosFiltro, tokenUsuario } from "../../utils/constants";
import ReactToPrint from "react-to-print";
import SelectCustom from "../../components/SelectCustom";
export default function Inicio() {
  const componentRef = useRef();
  const date = new Date();
  const [ano, setAno] = useState(date.getFullYear());
  const [dataAnosArray, setDataAnosArray] = useState([]);

  const carregarPeriodos = async () => {
    try {
      const response = await axios.post(urlPeriodosFiltro, {
        token: tokenUsuario,
      });
      setDataAnosArray(response.data);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    carregarPeriodos();
  }, [ano]);

  return (
    <>
      <div className="row">
        <div className="col-sm-2">
          <MenuSide />
        </div>
        <div className="col-sm-10">
          <br />
          <div className="card" style={{ marginRight: "1%" }}>
            <div className="card-header">
              <div className="row">
                <div
                  className="col-sm-3"
                  style={{ fontWeight: 700, fontSize: "25px" }}
                >
                  Início
                </div>
                <div className="col-sm-6"></div>
                <div className="col-sm-2">
                  <SelectCustom
                    value={ano}
                    setValue={(event) => {
                      event.preventDefault();
                      setAno(event.target.value);
                    }}
                    itens={dataAnosArray}
                    valueItem={"ano"}
                    nameItem={"ano"}
                  />
                </div>
                <div className="col-sm-1">
                  <Button
                    variant="contained"
                    color={"error"}
                    style={{
                      width: "100%",
                      height: 37,
                      borderRadius: "0.5rem",
                    }}
                  >
                    <ReactToPrint
                      trigger={() => (
                        <span>
                          <LocalPrintshopIcon style={{ fontSize: 16 }} />
                        </span>
                      )}
                      content={() => componentRef.current}
                    />{" "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div id="imprimir" ref={componentRef} style={{ marginRight: "1%" }}>
            <div className="row">
              <div className="col-sm-4">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{ fontWeight: 700, fontSize: "25px" }}
                      >
                        Valores por Forma de pagamento
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{ padding: 30 }}>
                    <GraficoValoresFormaPagamento ano={ano} />
                  </div>
                </div>
              </div>

              <div className="col-sm-8">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{ fontWeight: 700, fontSize: "25px" }}
                      >
                        Valores por Meses
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <GraficoValoresMeses ano={ano} />
                  </div>
                </div>
              </div>

              <div className="col-sm-12">&nbsp;</div>

              <div className="col-sm-4">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{ fontWeight: 700, fontSize: "25px" }}
                      >
                        Marmitas por Forma de pagamento
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{ padding: 30 }}>
                    <GraficoMarmitasFormaPagamento ano={ano} />
                  </div>
                </div>
              </div>

              <div className="col-sm-8">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{ fontWeight: 700, fontSize: "25px" }}
                      >
                        Contagem de itens por meses
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <GraficoMarmitasMeses ano={ano} />
                  </div>
                </div>
              </div>

              <div className="col-sm-12">&nbsp;</div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{ fontWeight: 700, fontSize: "25px" }}
                      >
                        Contagem de itens mais vendidos
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{ padding: 30 }}>
                    <GraficoItensMaisVendidosContagem ano={ano} />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{ fontWeight: 700, fontSize: "25px" }}
                      >
                        Valores dos itens mais vendidos
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{ padding: 30 }}>
                    <GraficoItensMaisVendidosValores ano={ano} />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">&nbsp;</div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{ fontWeight: 700, fontSize: "25px" }}
                      >
                        Marmitas por Empresas
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{ padding: 30 }}>
                    <GraficoMarmitasEmpresas ano={ano} />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{ fontWeight: 700, fontSize: "25px" }}
                      >
                        Valores por Empresas
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{ padding: 30 }}>
                    <GraficoValoresEmpresas ano={ano} />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
