import React, { useState, useEffect } from 'react';
import { MenuSide } from '../../components/MenuSide';
import { TableAtendimentos } from './components/TableAtendimentos';
import { urlAtendentes, tokenUsuario } from "../../utils/constants";
import InputCustom from '../../components/InputCustom';
import SelectCustom from "../../components/SelectCustom";
import axios from "axios";

export default function Atendimentos() {

    const [atendentes, setAtendentes] = useState([]);
    const [status, setStatus] = useState("todos");
    const [atendente, setAtendente] = useState("selecione");
    const [filtro, setFiltro] = useState('');
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');

    const carregarAtendentes = async () => {
        try {
            const response = await axios.post(urlAtendentes, {
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    const altererarAtendente = (event) => {
        event.preventDefault();
        setAtendente(event.target.value);
    };

    useEffect(() => {
        const run = async () => {
            let atendentesData = await carregarAtendentes();
            setAtendentes(atendentesData);
        };
        run();
    }, []);

    const statusTextArray = [
        { id: "todos", nome: "Todos os status" },
        { id: "1", nome: "Aguardando atendimento" },
        { id: "2", nome: "Em atendimento" },
        { id: "0", nome: "Atendimento finalizado" },
    ];

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br /> <div className='card'
                        style={{ marginRight: '1%', }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Atendimentos
                                </div>
                                <div className='col-sm-9 text-end'>

                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-2'>
                                    <h6>Nome do contato</h6>
                                    <InputCustom
                                        value={filtro}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setFiltro(event.target.value);
                                        }}
                                        type={'text'}
                                        placeHolder="Nome do cliente"
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <h6>&nbsp;Atendente</h6>
                                    <SelectCustom
                                        value={atendente}
                                        setValue={altererarAtendente}
                                        itens={atendentes}
                                        valueItem={"id"}
                                        nameItem={"nome"}
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <h6>&nbsp;Status</h6>
                                    <SelectCustom
                                        value={status}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setStatus(event.target.value);
                                        }}
                                        itens={statusTextArray}
                                        valueItem={"id"}
                                        nameItem={"nome"}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Data inicial</h6>
                                    <InputCustom
                                        value={dataInicial}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setDataInicial(event.target.value)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Data final</h6>
                                    <InputCustom
                                        value={dataFinal}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setDataFinal(event.target.value)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                            </div>
                            <br />
                            <TableAtendimentos nome={filtro} atendente={atendente} status={status} inicio={dataInicial} fim={dataFinal} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
