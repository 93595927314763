import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
} from "@mui/material";

export const ItemBuscaProduto = ({ nome }) => {
  return (
    <>
      <Card
        sx={{
          maxWidth: '100%',
        }}
      >
        <CardActionArea>
          <CardContent>
            {nome}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};