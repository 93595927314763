import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { Button, FormControl, TextField } from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { urlAlterarEmpresas, urlInformacoesEmpresas, urlApagarEmpresas, tokenUsuario } from "../../utils/constants";
import ButtonCustom from '../../components/ButtonCustom';

export default function AlterarProduto() {

    const navigate = useNavigate();
    const [nome, setNome] = useState('');

    const url = window.location.href;
    const urlBase = new URL(url);

    const informacoesContato = async () => {
        try {
            const response = await axios.post(urlInformacoesEmpresas, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    const alterarInformacoes = async () => {
        try {
            const response = await axios.post(urlAlterarEmpresas, {
                nome: nome,
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            if (response.data == 1) {
                navigate({
                    pathname: "/empresas",
                });
            }
        } catch (error) {
            return null;
        }
    };

    const excluirInformacoes = async () => {
        try {
            const response = await axios.post(urlApagarEmpresas, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            if (response.data == 1) {
                navigate({
                    pathname: "/empresas",
                });
            }
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {
        const run = async () => {
            const info = await informacoesContato();
            setNome(info.nome);
        }
        run();
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Alterar empresa
                                </div>
                                <div className='col-sm-9 text-end'>
                                    <Button
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={() => {
                                            excluirInformacoes();
                                        }}
                                        style={{
                                            color: '#111B21',
                                            width: 50,
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        <DeleteForeverOutlinedIcon style={{ color: '#ffffff' }} />
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <h6>Nome</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            placeholder='Nome'
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setNome(event.target.value)
                                            }}
                                            value={nome}
                                            type="text"
                                            InputProps={{
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                               <div className='col-sm-7'></div>
                                <div className='col-sm-2 text-end'>
                                <ButtonCustom
                                        onClick={() => {
                                            alterarInformacoes();
                                        }}
                                        color={"error"}
                                        texto={"Confirmar"}
                                        icon={null}
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
