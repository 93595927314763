import React from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import axios from "axios";
import { urlIniciarAtendimento, tokenUsuario } from "../../../utils/constants";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonCustom from "../../../components/ButtonCustom";

export const ModalDialog = ({
  isOpen,
  setIsOpen,
  nome,
  id,
  numero,
  closeMainModal,
  alterarChat,
}) => {
  let navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
  };

  const iniciarAtendimento = () => {
    axios
      .post(urlIniciarAtendimento, {
        id: id,
        token: tokenUsuario,
      })
      .then((response) => {
        if (response.data.status == "iniciado") {
          setIsOpen(false);
          closeMainModal();
          navigate({
            pathname: "/chat",
            search: createSearchParams({
              numero: numero,
            }).toString(),
          });
          alterarChat(numero, nome);
        } else {
          alert("Outra pessoa esta atendendo esse cliente");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Iniciar atendimento"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja iniciar o atendimento? Cliente: {nome}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonCustom color={'error'} texto={'Cancelar'} variant="contained" onClick={handleClose} />
          <ButtonCustom color={'success'} texto={'Iniciar'} variant="contained" onClick={iniciarAtendimento} />
        </DialogActions>
      </Dialog>
    </>
  );
};
