/* eslint-disable react/no-unknown-property */
import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import axios from "axios";
import { Button, CircularProgress, Paper } from '@mui/material';
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { tokenUsuario, urlRelatorioMarmitasPorEmpresa } from '../../utils/constants';
import { MenuSide } from '../../components/MenuSide';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import FilterListIcon from '@mui/icons-material/FilterList';
import html2pdf from 'html2pdf.js';
import InputCustom from '../../components/InputCustom';

export function converterDataFormato(dataOriginal) {
    //@ts-ignore
    const partes = dataOriginal.split(" ");
    const dataPartes = partes[0].split("-");

    const dia = dataPartes[2];
    const mes = dataPartes[1];
    const ano = dataPartes[0];

    const dataFormatada = `${dia}/${mes}/${ano}`;

    return dataFormatada;
}

export default function RelatorioMarmitasEmpresa() {

    const filtrarInformacoes = async () => {
        setLoading(true);
        try {
            const response = await axios.post(urlRelatorioMarmitasPorEmpresa, {
                token: tokenUsuario,
                dataInicial: dataInicial,
                dataFinal: dataFinal
            })
            if (response.data.pedidos !== null) {
                setPedidos(response.data.pedidos);
            }
            if (response.data.itens !== null) {
                setItens(response.data.itens);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const exportarParaExcel = (event) => {
        event.preventDefault();

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(itens.map(pedido => {

            const totalPorEmpresa = pedidos
                .filter(item => item.empresa_id == pedido.empresa_id)
                .reduce((acc, curr) => acc + curr.total_soma, 0);

            return {
                'Empresa': pedido.nome_empresa,
                'Marmitas': pedido.total_itens || 0,
                'Valor': `R$ ${totalPorEmpresa.toFixed(2)}`,
                'Período': `${dataInicial} / ${dataFinal}`,
            };
        }));

        const wb = { Sheets: { 'pedidos': ws }, SheetNames: ['pedidos'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'relatorio_pedidos' + fileExtension);
    }

    function addZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const exportarParaPDF = async () => {
        const element = componentRef.current;
        let date = new Date();
        const dia = addZero(date.getDate());
        const mes = addZero(date.getMonth() + 1);
        const ano = date.getFullYear();
        const hora = addZero(date.getHours());
        const minuto = addZero(date.getMinutes());
        const segundo = addZero(date.getSeconds());

        const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;

        const pdfOptions = {
            margin: 10,
            filename: `relatorio_marmitas_${dataInicial}_${dataFinal}_gerado_${dataFormatada}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        setProgressOpen(true);
        await html2pdf().from(element).set(pdfOptions).save();
        setProgressOpen(false);
    };


    const [progressOpen, setProgressOpen] = useState(false);

    const componentRef = useRef();
    const [loading, setLoading] = useState(0);
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [pedidos, setPedidos] = useState([]);
    const [itens, setItens] = useState([]);

    return (
        <>
            <Dialog open={progressOpen} onClose={() => setProgressOpen(false)}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <DialogTitle>Gerando PDF...</DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-12' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Relatório de marmitas por Empresa
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-2'>
                                    <h6>Data inicial</h6>
                                    <InputCustom
                                        value={dataInicial}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setDataInicial(event.target.value)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Data final</h6>
                                    <InputCustom
                                        value={dataFinal}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setDataFinal(event.target.value)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <br />
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            filtrarInformacoes();
                                        }}
                                        style={{
                                            width: '100%',
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        BUSCAR  &nbsp;&nbsp; <FilterListIcon style={{ fontSize: 16 }} />
                                    </Button>
                                </div>
                                <div className='col-sm-1'>
                                    <br />
                                    <Button
                                        variant='contained'
                                        color={'error'}
                                        onClick={() => {
                                            setProgressOpen(true);
                                            exportarParaPDF();
                                        }}
                                        style={{
                                            width: '100%',
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        <PictureAsPdfIcon style={{ fontSize: 16 }} />
                                    </Button>
                                </div>
                                <div className='col-sm-1'>
                                    <br />
                                    <a
                                        id="downloadLink"
                                        onClick={exportarParaExcel}
                                    >
                                        <Button
                                            variant='contained'
                                            color={'success'}

                                            style={{
                                                width: '100%',
                                                height: 39,
                                                borderRadius: '0.5rem',
                                                marginTop: '4px',
                                            }}
                                        >
                                            <ArticleIcon style={{ fontSize: 16 }} />
                                        </Button>
                                    </a>
                                </div>
                                <div className='col-sm-1'>
                                    <br />
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button
                                                variant='contained'
                                                color='info'
                                                style={{
                                                    width: '100%',
                                                    height: 39,
                                                    borderRadius: '0.5rem',
                                                    marginTop: '4px',
                                                }}
                                            >
                                                <span><LocalPrintshopIcon style={{ fontSize: 16 }} /></span>
                                            </Button>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                </div>
                            </div>

                            <br />

                            <div id='imprimir' ref={componentRef}>

                                {loading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '20%' }}>
                                        <CircularProgress color="primary" />
                                    </div>
                                ) : (
                                    <>
                                        <div className="containerFaixaRelatorio">

                                            <div className="itemImagemFaixaRelatorio">
                                                <img src="https://sistema-marmita-express.gerenciadores.com.br/logo192.png" className="ImagemFaixaRelatorio" alt="" />
                                            </div>

                                            <div className="itemFaixaRelatorio">
                                                &nbsp;&nbsp;MARMITA EXPRESS<br />
                                                <h6 className="descricaoItemRelatorio" style={{ marginTop: 10 }}>&nbsp;&nbsp;&nbsp;&nbsp;Relatório de marmitas por Empresa</h6>
                                            </div>

                                        </div>
                                        <div component={Paper}>
                                            <table className='table table' aria-label="simple table">
                                                <thead>
                                                    <tr>
                                                        <th>Empresa</th>
                                                        <th>Marmitas</th>
                                                        <th>Valor</th>
                                                        <th>Período</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {itens.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.nome_empresa}</td>
                                                            <td>{item.total_itens}</td>
                                                            <td>
                                                                {pedidos.filter(pedido => item.empresa_id == pedido.empresa_id).map((pedido, pedidoIndex) => (
                                                                    <span key={pedidoIndex}>
                                                                        R$ {pedido.total_soma}
                                                                    </span>
                                                                ))}
                                                            </td>
                                                            <td>{converterDataFormato(dataInicial)} / {converterDataFormato(dataFinal)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
