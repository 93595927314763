import React from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const ModalDialog = ({ isOpen, setIsOpen, mensagem, acaoBotao }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Informações do Pedido"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {mensagem}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.preventDefault();
              acaoBotao(false);
            }}
          >
            Não
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              acaoBotao(true);
            }}
            autoFocus
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
