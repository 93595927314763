/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, createSearchParams } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { Button, FormControl, TextField, Select, Chip, MenuItem } from "@mui/material";
import { urlAlterarPedido, urlInformacoesPedido, urlAdicionarRemoverItens, tokenUsuario } from "../../utils/constants";

import { AlertCustom } from "../../components/AlertCustom";

export default function AlterarPedido() {

    const url = window.location.href;
    const urlBase = new URL(url);
    const id = urlBase.searchParams.get('id');
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    const [data, setData] = useState('');

    const [pedidosL, setPedidosL] = useState([]);
    const [itensL, setItensL] = useState([]);
    const [ingredientesL, setIngredientesL] = useState([]);

    const [successAlert, setSuccessAlert] = useState(false);
    const [erroAlert, setErrorAlert] = useState(false);

    const statusTextArray = [
        { id: "1", nome: "Pendente" },
        { id: "2", nome: "Em curso" },
        { id: "4", nome: "Finalizado" },
        { id: "5", nome: "Cancelado" },
        { id: "6", nome: "Reembolsado" },
        { id: "7", nome: "Em lançamento" },
    ];

    const carregarPedidoLancamento = async () => {
        try {
            const response = await axios.post(urlInformacoesPedido, {
                id: id,
                token: tokenUsuario,
            })
            setItensL(response.data.itens);
            setPedidosL(response.data.pedidos[0]);
            setIngredientesL(response.data.ingredientes);
            setData(response.data.pedidos[0].data);
            setStatus(response.data.pedidos[0].status);
        } catch (error) {
            return null;
        }
    }

    const alterarPedido = async () => {
        try {
            const response = await axios.post(urlAlterarPedido, {
                id: id,
                data: data,
                status: status,
                token: tokenUsuario,
            })
            if (response.data == 1) {
                setSuccessAlert(true);
            } else {
                setErrorAlert(true);
            }
        } catch (error) {
            setErrorAlert(true);
        }
    }

    const adicionarOuRemoverItens = async () => {
        try {
            const response = await axios.post(urlAdicionarRemoverItens, {
                id: id,
                token: tokenUsuario,
            })
            if (response.data == 1) {
                let clienteLocal = {
                    id: pedidosL.user_id,
                    nome: pedidosL.nome_cliente,
                    nome_empresa: pedidosL.nome_empresa
                };

                localStorage.setItem('cliente_pedido', JSON.stringify(clienteLocal));
                navigate({
                    pathname: "/pedidos/adicionar",
                    search: createSearchParams({
                        id: id,
                    }).toString(),
                });
            }
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {
        carregarPedidoLancamento();
    }, [id]);

    return (
        <>
            <AlertCustom
                texto={"Informações alteradas com sucesso!"}
                color="success"
                isOpen={successAlert}
                setIsOpen={() => {
                    setSuccessAlert(false);
                }}
                tempo={2000}
            />
            <AlertCustom
                texto={"Não foi possível alteradas as informações!"}
                color="error"
                isOpen={erroAlert}
                setIsOpen={() => {
                    setErrorAlert(false);
                }}
                tempo={3000}
            />
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-9' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Detalhes do pedido #{id}
                                </div>
                                <div className='col-sm-3'>
                                    <Button
                                        variant={'contained'}
                                        color={'error'}
                                        onClick={() => {
                                            adicionarOuRemoverItens();
                                        }}
                                        style={{
                                            width: '100%',
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        Adicionar/Remover itens
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <div className='card'>
                                        <span style={{ margin: 8 }}> {pedidosL.nome_cliente} - {pedidosL.nome_empresa}</span>
                                    </div>
                                    <br />
                                    <h6>Data</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setData(event.target.value)
                                            }}
                                            value={data}
                                            type="date"
                                            InputProps={{
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>

                                    <h6>Status</h6>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        style={{
                                            borderRadius: '0.5rem',
                                            height: "39px",
                                            marginLeft: "3px",
                                            marginRight: "3px",
                                            width: '100%'
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    borderRadius: '0.5rem',
                                                },
                                            }
                                        }}
                                        onChange={(event) => {
                                            event.preventDefault();
                                            setStatus(event.target.value);
                                        }}
                                    >
                                        {statusTextArray.map((item, index) => (
                                            <MenuItem value={item.id} key={index}>{item.nome}</MenuItem>
                                        ))}
                                    </Select>
                                    <br /><br />
                                    <Button
                                        variant={'contained'}
                                        color={'error'}
                                        onClick={() => {
                                            alterarPedido();
                                        }}
                                        style={{
                                            width: '100%',
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        Alterar
                                    </Button>
                                </div>
                                <div className='col-sm-8'>
                                    <div className='card' style={{ padding: 20 }}>

                                        <Chip color={'success'} style={{ width: '29%' }} label={`Total R$ ${pedidosL.valor}`} />
                                        <br />
                                        <h6>Forma de pagamento</h6>
                                        <span>{pedidosL.forma_pagamento}</span>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        {itensL.map((item, itemIndex) => (
                                            <div className='col-sm-4' key={itemIndex}>
                                                <div className='card' style={{ marginBottom: 5 }} >
                                                    <div
                                                        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1, marginRight: 10 }}>

                                                    </div>
                                                    <div style={{ padding: 10 }}>
                                                        <span>{item.nome_produto}</span>
                                                        <div>
                                                            Quantidade : {item.qnt} / R$ {item.valor_item} / R$ {item.total}
                                                        </div>
                                                        <br />
                                                        Adicionais
                                                        <br />
                                                        {ingredientesL.filter(ingrediente => ingrediente.item_id == item.id).map((ingrediente, ingredienteIndex) => (
                                                            <div key={ingredienteIndex}>
                                                                <span>{ingrediente.nome_ingrediente}</span>
                                                                <div>
                                                                    Quantidade : {ingrediente.qnt} / R$ {ingrediente.total} = R$ {ingrediente.total}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                                <div className='col-sm-9 text-end'>

                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
